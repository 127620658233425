import {
  ReactElement, useState, useEffect
} from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
  Chip,
  Box,
  ButtonGroup
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { element } from 'prop-types';
import { useCategories } from '../../hooks';

import CloseX from '../ui/CloseX';

import Categories from '../edit-product/Categories';

const SearchFilterDialog = (props:GenericObject):ReactElement => {
  const [mainCategory, setMainCategory] = useState(1);
  const [idSort, setIdSort] = useState(1);

  const { t } = useTranslation();

  const {
    open,
    onClose,
    setOrderBy,
    setOrder,
    setCategories,
    sharedProductStore
  } = props;

  // user-defined hook to manage the categories
  const {
    tree,
    countCategories,
    categories,
    toggleFilter,
    toggleCheckbox,
    processSearchTerm,
    countMainCategories,
    getListCategories,
    toggleAllVisibleCategories,
  } = useCategories({ modeSearchDialog: true });

  const arraySort:Array<any> = [
    { id: 1, name: 'Neueste zuerst' },
    { id: 2, name: 'Älteste zuerst' },
    { id: 3, name: 'Alphabetish aufsteigend' },
    { id: 4, name: 'Alphabetish absteigend' },
  ];

  const handleAddFilter = () => {
    // apply new sort and / or categories list
    let params:GenericObject = {};
    if (idSort === 1) params = { orderBy: 'time', order: 'desc' };
    if (idSort === 2) params = { orderBy: 'time', order: 'asc' };
    if (idSort === 3) params = { orderBy: 'alphabet', order: 'asc' };
    if (idSort === 4) params = { orderBy: 'alphabet', order: 'desc' };

    setOrderBy(params.orderBy);
    setOrder(params.order);

    const list = getListCategories();
    setCategories(list);

    onClose();
  };

  const handleRemoveFilter = () => {
    setCategories('');
    sharedProductStore.setState({ searchCategories: [] });
    setCategories('');
    setOrderBy('time');
    setOrder('desc');
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={onClose}
    >
      <DialogContent sx={{ mb: 3, pb: 0, minHeight: '80vh' }}>
        <Box
          sx={{
            pt: 1, pb: 2.5, pl: 3, pr: 5, mx: -3, position: 'relative', borderBottom: 2, borderColor: 'secondary.main', display: 'flex', flexWrap: 'wrap', alignItems: 'center'
          }}
        >
          <Typography variant="h6" fontSize={16} color="black" fontWeight="bold" lineHeight={1} marginRight={2}>Sortieren nach:</Typography>
          <ButtonGroup>
            {arraySort.map((elem:GenericObject):ReactElement => (
              <Button
                key={`${elem.type}.${elem.id}`}
                variant="contained"
                color="secondary"
                onClick={() => setIdSort(elem.id)}
                style={{ opacity: elem.id === idSort ? '1' : '0.6' }}
                sx={{
                  my: 0.5, px: 2.5, py: 0.5
                }}
              >
                {`${elem.name}`}
              </Button>
            ))}
          </ButtonGroup>
          <CloseX onClose={onClose} />
        </Box>
        <Box
          sx={{
            py: 1, px: 3, mx: -3, mb: 1, position: 'relative', display: 'flex', flexWrap: 'wrap', alignItems: 'center'
          }}
        >
          {/* <Typography variant="h6" fontSize={16} color="black" fontWeight="bold" lineHeight={1} marginRight={2}>Filtern nach:</Typography> */}
          {categories.map((cat:GenericObject):ReactElement => (
            <Chip
              key={`${cat.id}`}
              sx={{
                mr: 2, my: 0.5, px: 0, py: 1, bgcolor: 'transparent', borderRadius: 0, borderTop: 0, borderLeft: 0, borderRight: 0, borderBottom: 2, color: 'black'
              }}
              label={`${cat.name}`}
              variant={cat.id === mainCategory ? 'filled' : 'filled'}
              onClick={() => setMainCategory(cat.id)}
              style={{ opacity: cat.id === mainCategory ? '1' : '0.3' }}
            />
          ))}
        </Box>
        <Categories
          tree={tree}
          filterTree={mainCategory}
          countCategories={countCategories}
          countMainCategories={countMainCategories}
          toggleFilter={toggleFilter}
          toggleCheckbox={toggleCheckbox}
          processSearchTerm={processSearchTerm}
          toggleAllVisibleCategories={toggleAllVisibleCategories}
        />
      </DialogContent>
      <DialogActions
        sx={{
          pt: 0, py: 2.5, mt: -2.5, borderTop: 2, borderColor: 'secondary.main'
        }}
      >
        <Button
          type="submit"
          variant="text"
          size="small"
          onClick={handleRemoveFilter}
          sx={{
            mr: 2, px: 3, py: 0.5
          }}
        >
          {t('search.button.resetFilter')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          onClick={handleAddFilter}
          sx={{
            mr: 2, px: 3, py: 0.5
          }}
        >
          {t('search.button.applyFilter')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          size="small"
          onClick={onClose}
          sx={{
            mr: 2, px: 3, py: 0.5
          }}
        >
          {t('common.button.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SearchFilterDialog;
