/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { FC, ReactElement, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
// import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography
} from '@mui/material';

import { InfoOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import FormInputText from '../components/ui/FormInputText';
import PreviewCard from '../components/projects/PreviewCard';
import PlaceholderLogoCompany from '../assets/images/placeholder_company.png';

import { useCategories } from '../hooks';

import Categories from '../components/edit-product/Categories';

import {
  ruleProjectName, ruleProjectSummary
} from '../rules';

import { useAppStore, useProductStore, useUserStore } from '../state';
import UploadAndDisplayImage from '../components/ui/UploadAndDisplayImage';
import Sticky from '../components/app/Sticky';
import Logos from '../components/edit-product/Logos';

const EditProject:FC = ():ReactElement => {
  // user-defined hook to manage the categories
  const {
    step,
    touched,
    name,
    summary,
    tree,
    countCategories,
    currentProductProject,
    isCrossIndustries,
    toggleIndustry,
    countMainCategories,
    toggleAllVisibleCategories,
    toggleFilter,
    toggleCheckbox,
    processSearchTerm,
    getListCategories,
    validateCheckedIndustriesFunctionalities,
    setStep,
    setTouched,
    setName,
    setSummary,

  } = useCategories();

  const { t } = useTranslation();
  const {
    prodAddProject, prodEditProject, prodEditProjectLogo
  } = useProductStore.getState();

  const { company } = useUserStore.getState();
  const logoCompany = company.logo;
  const [logoTouched, setLogoTouched] = useState(false);
  const [logoProductString, setLogoProductString] = useState('');
  // eslint-disable-next-line no-nested-ternary
  const [currentLogo, setCurrentLogo] = useState(currentProductProject ? (currentProductProject.logo !== null ? currentProductProject.logo : logoCompany || PlaceholderLogoCompany) : logoCompany || PlaceholderLogoCompany);
  const navigate = useNavigate();

  const {
    handleSubmit, control, formState, register
  } = useForm();
  const { dirtyFields } = formState;

  const onSubmitNameSummary = (data: GenericObject) => {
    setTouched(true);
    setStep((currentStep) => currentStep + 1);
    setName(data.name);
    setSummary(data.summary);

    return true;
  };

  const [previewItem, setPreviewItem] = useState({
    id: -1,
    name,
    type: 'project',
    logo: '',
    summary,
    company,
    tree,
  });

  // Saving the new product / the changes
  const handleSave = () => {
    const allCategories = getListCategories();
    if (allCategories.length === 0) return false;

    const dataItem = {
      id: currentProductProject?.id,
      name,
      summary,
      categories: allCategories,
    };

    // add or edit product
    const promise = currentProductProject
      ? prodEditProject(dataItem)
      : prodAddProject(dataItem);

    promise
      .then((data: GenericObject) => {
        const {
          success, id,
        } = data;
        if (success && logoTouched) {
          const result = prodEditProjectLogo(id === undefined ? currentProductProject.id : id, logoProductString);

          result
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .then((success: boolean) => {
              if (success) {
                useProductStore.getState().prodResetAll();
                return navigate('/app/projects');
              }
              useProductStore.getState().prodResetAll();
              return navigate('/app/projects');
            });
          useProductStore.getState().prodResetAll();
          return navigate('/app/projects');
        }
        useProductStore.getState().prodResetAll();
        return navigate('/app/projects');
      });
    return true;
  };

  const handleInfo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('editProducts.whichIndustries.info.header') },
      contentItems: [
        {
          text: t('editProducts.whichIndustries.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const onSaveProductLogo = (imageData:string) => {
    setLogoTouched(true);
    setLogoProductString(imageData);
  };

  const onDeleteImage = () => {
    const dialogData = {
      title: { text: t('dialog.pleaseConfirm') },
      contentItems: [
        {
          text: t('dialog.confirmDeleteImage'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.cancel'),
          color: 'secondary',
        },
        {
          text: t('common.button.delete'),
          color: 'error',
          onClick: () => {
            setCurrentLogo(PlaceholderLogoCompany);
            setLogoTouched(true);
            setLogoProductString('');
          }
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleInfoStep2 = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('editProducts.steps.application.step2.info.header') },
      contentItems: [
        {
          text: t('editProducts.steps.application.step2.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleGoToList = () => {
    useProductStore.setState({ searchCategories: [] });
    navigate('/app/projects');
  };

  const handleBack = () => setStep(1);

  const handleCancel = () => {
    // if some fields are touched so ask for quitting
    if (Object.keys(dirtyFields).length === 0 && !touched) return handleGoToList();

    setTouched(true);

    const dialogData = {
      title: { text: 'Sind Sie sicher?' },
      contentItems: [
        {
          text: 'Sie haben bereits Daten erfasst oder eine Auswahl getroffen, dies würde beim Abbrechen verloren gehen. Sind sie sicher, dass Sie die Gesuch-Erfassung abbrechen wollen?',
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: 'Nein, weiter bearbeiten',
          color: 'secondary',
        },
        {
          text: 'Ja, abbrechen',
          color: 'error',
          onClick: handleGoToList
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleGoToLastPage = () => {
    // validation of selected categories
    if (!validateCheckedIndustriesFunctionalities()) {
      const dialogData = {
        title: { text: 'Hinweis.' },
        contentItems: [{ text: 'Bitte wählen Sie bei den Branchen und Funktionen mindestens einen Eintrag aus.' }],
        actionItems: [{ text: 'OK, verstanden', color: 'secondary' }],
      };
      useAppStore.setState({ dialogData });

      return false;
    }

    setPreviewItem({
      id: -1,
      type: 'project',
      name,
      logo: currentLogo,
      summary,
      company,
      tree,
    });

    return setStep(3);
  };

  const handleInfoLogo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: 'Produktlogo' },
      contentItems: [
        {
          text: 'Laden Sie Ihr Produktlogo hoch. Sollten Sie kein Produktlogo haben wird Ihr Unternehmenslogo verwendet.',
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const customStep : GenericObject = {
    backgroundColor: '#B50900',
    height: '5px',
    flexGrow: '1',
    opacity: '0.3',
    '&[data-filled=true]': {
      opacity: '1',
    }
  };

  return (
    <Box width="100%">
      <Box sx={{ backgroundColor: 'background.default', width: '100%' }}>
        <Typography variant="h1" color="black" fontWeight="bold" marginBottom={3}>
          {`${t('editProducts.application')} ${currentProductProject ? t('editProducts.edit') : t('editProducts.add')}`}
        </Typography>
        <Box
          sx={{
            display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridColumnGap: '15px', width: '60%'
          }}
          marginBottom={3}
        >
          <Box sx={customStep} data-filled={step === 1 || step > 1} />
          <Box sx={customStep} data-filled={step === 2 || step > 2} />
          <Box sx={customStep} data-filled={step === 3} />
        </Box>
      </Box>
      {step === 1 && (
        <Typography gutterBottom variant="h5" fontWeight="bold">{`${t('editProducts.step')} 1 ${t('editProducts.of')} 3: ${t('editProducts.steps.application.step1.header')}`}</Typography>)}
      {step === 2 && (
        <div style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2
        }}
        >
          <Typography variant="h5" fontWeight="bold">{`${t('editProducts.step')} 2 ${t('editProducts.of')} 3: ${t('editProducts.steps.application.step2.header')}`}</Typography>
          <IconButton size="large" color="default" onClick={handleInfoStep2}><InfoOutlined /></IconButton>
        </div>
      )}
      {step === 3 && (
      <>
        <Typography gutterBottom variant="h5" fontWeight="bold">{`${t('editProducts.step')} 3 ${t('editProducts.of')} 3: ${t('editProducts.steps.application.step3.header')}`}</Typography>
        <Box sx={{ mt: 4, color: 'black' }}>
          <Typography marginBottom={3} style={{ color: 'black' }}>
            {t('editProducts.steps.application.step3.paragraph1')}
          </Typography>
        </Box>
      </>
      )}
      {step === 1 && (
        <Paper sx={{
          p: 2, mb: 2, maxHeight: '70vh', overflow: 'auto', width: '100%'
        }}
        >
          <form onSubmit={handleSubmit(onSubmitNameSummary)} style={{ marginTop: '20px' }}>
            <FormInputText
              name="name"
              defaultValue={currentProductProject ? currentProductProject.name : localStorage.getItem('timeStamp') ? `Suche vom ${localStorage.getItem('timeStamp')}` : ''}
              control={control}
              ref={register}
              label={t('editProducts.input.applicationName')}
              rules={ruleProjectName}
              sx={{ width: '100%', maxWidth: '600px', mb: 4 }}
            />
            <br />
            <FormInputText
              name="summary"
              defaultValue={currentProductProject ? currentProductProject.summary : ''}
              control={control}
              ref={register}
              label={t('editProducts.input.description')}
              multiline
              rows={5}
              maxRows={4}
              rules={ruleProjectSummary}
              sx={{ width: '100%', maxWidth: '600px', mb: 4 }}
            />
          </form>
        </Paper>
      )}
      {step === 2 && (
        <Paper sx={{
          p: 2, mb: 2, maxHeight: '70vh', overflow: 'auto', width: '100%'
        }}
        >
          <Categories
            tree={tree}
            toggleAllVisibleCategories={toggleAllVisibleCategories}
            countMainCategories={countMainCategories}
            countCategories={countCategories}
            isCrossIndustries={isCrossIndustries}
            toggleFilter={toggleFilter}
            toggleCheckbox={toggleCheckbox}
            processSearchTerm={processSearchTerm}
          />
        </Paper>
      )}
      {step === 3 && (
      <Box sx={{
        p: 2, mb: 2, minHeight: '70vh', maxHeight: '70vh', overflow: 'auto', width: '100%'
      }}
      >
        <PreviewCard
          key={previewItem.id}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...previewItem}
          screenType="previewProduct"
        />
      </Box>
      )}
      {step === 1 && (
      <Box sx={{ position: 'sticky', bottom: 10 }}>
        <Box>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={handleCancel}
            sx={{ mr: 2 }}
          >
            {t('common.button.cancel')}
          </Button>
          <Button type="submit" variant="contained" color="primary" onClick={handleSubmit(onSubmitNameSummary)}>
            {t('common.button.next')}
          </Button>
        </Box>
      </Box>
      )}
      {step === 2 && (
        <Box sx={{ position: 'sticky', bottom: 10 }}>
          <Box>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              sx={{ mr: 2 }}
            >
              {t('common.button.cancel')}
            </Button>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleBack}
              sx={{ mr: 2 }}
            >
              {t('common.button.back')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleGoToLastPage}
            >
              {t('common.button.next')}
            </Button>
          </Box>
        </Box>
      )}
      {step === 3 && (
        <Box sx={{ position: 'sticky', bottom: 10 }}>
          <Box>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              sx={{ mr: 2 }}
            >
              {t('common.button.cancel')}
            </Button>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleBack}
              sx={{ mr: 2 }}
            >
              {t('common.button.edit')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSave}
            >
              {t('common.button.save')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EditProject;
