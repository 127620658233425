/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  CardActions,
  Card as MuiCard,
  Button,
  Tabs,
  Tab,
  Typography,
  Avatar,
  AvatarGroup,
  Link,
  Tooltip,
  Chip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import {
  AddOutlined as AddIcon,
  EditOutlined as EditIcon,
  DeleteOutlineOutlined as DeleteIcon,
  RemoveRedEyeOutlined as ActivateIcon,
  VisibilityOffOutlined as DeactivateIcon,
  Bookmark as BookmarkMarkedIcon,
  BookmarkBorder as BookmarkIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  InfoOutlined as InfoIcon,
  DoneAll as ReadIcon,
  Extension as MatchingIcon,
  BusinessOutlined as CompanyIcon,
  OpenInNew as ExternalLinkIcon,
  LocationOnOutlined as PinIcon
} from '@mui/icons-material';
import PlaceholderImage from '../../assets/images/placeholder_company.png';
import {
  buildCombiproductSolutionList,
  colorType, getDateFromUnixTimestamp, goToCompanyProfile
} from '../../utils';
import { useCategories } from '../../hooks';
import HoverMenu from './HoverMenu';
import { CardHeader } from './CardHeader';
import CategoriesTabs from '../app/CategoriesTabs';
import OwnerImg from '../../assets/images/placeholder_person.png';

interface TabProps {
  value?: number,
  index?: number
}

const TabPanel: FC<TabProps> = ({
  children,
  value,
  index,
  ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  /* eslint-disable react/jsx-props-no-spreading */
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        {children}
      </Box>
    )}
  </div>
);

TabPanel.defaultProps = {
  index: 0,
  value: 0,
};

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const sxTab = {
  paddingX: 0, mr: 2, textTransform: 'capitalize', alignItems: 'flex-start', minWidth: '70px', fontWeight: 'bold',
};

const DetailCard: React.FC<CardProps> = (props: CardProps) => {
  const {
    id,
    name,
    type,
    logo,
    item,
    summary,
    url,
    direction,
    combiproductCompanies,
    status,
    owner,
    suggestionsCount,
    company,
    offerMessage,
    offerCreated,
    referenceModels,
    isCombiProduct,
    detailTabValue,
    handleEdit,
    handleRemove,
    handleActivate,
    handleActivateProject,
    handleDeactivateProject,
    handleLinkup,
    handleBookmark,
    handleLinkupBookmark,
    handleRemoveBookmark,
    handleBlacklistSuggestion,
    handleLinkupBlacklisted,
    handleBookmarkBlacklisted,
    handleMatching,
    handleStartCombiProduct,
    handleResultList,
    handleConfirmOffer,
    handleDeclineOffer,
    handleWithdrawOffer,
    handleConfirmCombiProduct,
    handleDeclineCombiProduct,
    handleUnread,
    handleRead,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    tree,
  } = useCategories({ currentItem: props });

  const getCurrentTabValuefromString = (stringval: string | undefined) => {
    switch (stringval) {
      case 'Allgemein':
        return 0;
      case 'Kategorie':
        return 1;
      case 'Nachricht':
        return 3;
      case 'Zusammenfassung':
        return 3;
      case 'Refferenzmodelle':
        if (isCombiProduct === true) {
          return 4;
        }
        return 3;
      default:
        return 0;
    }
  };

  const [tabValue, setTabValue] = useState(getCurrentTabValuefromString(detailTabValue));
  const tabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const typename = () => {
    switch (type) {
      case 'solution':
        if (isCombiProduct === true) {
          return t('card.type.combiproduct');
        }
        return t('card.type.singleproduct');
      case 'combiProduct':
        return t('card.type.combiproduct');
      case 'project':
        return t('card.type.application');
      default:
        return t('card.type.typeMissing');
    }
  };

  const children:any = [];

  tree.industries.forEach((elem: GenericObject) => {
    if (elem.id === 120 && elem.checked) {
      children.push({ id: elem.id, name: elem.name });
    }
    if (elem.id !== 120) {
      if (elem.checked) {
        children.push({ id: elem.id, name: elem.name });
      }
    }
  });

  const [actions, setActions] = useState<CardAction[]>([]);
  const [overflowActions, setOverflowActions] = useState<CardAction[]>([]);

  const path = useLocation().pathname.split('?')[0];
  const page: PageType = (path.slice(path.lastIndexOf('/') + 1) as PageType);
  const isClickable = page !== 'preview' && page !== 'companydetails' && page !== 'externalsearch' && page !== 'edit-singleproduct';
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);

  const newRefModels = referenceModels || [];

  const generateCardActions = () => {
    const newActions:CardAction[] = [];

    switch (page) {
      case 'portfolio':
        if (type === 'solution') {
          newActions.push({ label: t('common.button.edit'), startIcon: <EditIcon />, onClick: handleEdit });
          newActions.push({ label: `Matching (${suggestionsCount})`, startIcon: <MatchingIcon />, onClick: handleMatching });
          newActions.push({ label: t('common.button.delete'), startIcon: <DeleteIcon />, onClick: handleRemove });
        }
        if (type === 'combiProduct') {
          newActions.push({
            label: t('common.button.activate'), startIcon: <ActivateIcon />, disabled: status === 50 || status === 240 || status === 10 || !owner, onClick: handleActivate
          });
          if (status === 50 && owner) {
            newActions.push({
              label: `Matching (${suggestionsCount})`, startIcon: <MatchingIcon />, onClick: handleMatching
            });
          }
          newActions.push({
            label: t('common.button.edit'), startIcon: <EditIcon />, disabled: status === 50 || status === 240 || status === 10 || !owner, onClick: handleEdit
          });
          newActions.push({
            label: t('common.button.delete'), startIcon: <DeleteIcon />, disabled: !owner, onClick: handleRemove
          });
        }
        break;
      case 'projects':
        if (status === 40) {
          newActions.push({ label: t('common.button.activate'), startIcon: <ActivateIcon />, onClick: handleActivateProject });
          newActions.push({ label: 'Ergebnisliste', startIcon: <MatchingIcon />, onClick: handleResultList });
          newActions.push({ label: t('common.button.edit'), startIcon: <EditIcon />, onClick: handleEdit });
        }
        if (status === 50) {
          newActions.push({ label: 'Ergebnisliste', startIcon: <MatchingIcon />, onClick: handleResultList });
          newActions.push({ label: t('common.button.edit'), startIcon: <EditIcon />, onClick: handleEdit });
          newActions.push({ label: t('common.button.deactivate'), startIcon: <DeactivateIcon />, onClick: handleDeactivateProject });
        }
        newActions.push({ label: t('common.button.delete'), startIcon: <DeleteIcon />, onClick: handleRemove });
        break;
      case 'incoming':
        if (direction === 'incoming' && type !== 'combiProduct') {
          newActions.push({ label: t('common.button.confirm'), startIcon: <CheckIcon />, onClick: handleConfirmOffer });
          newActions.push({ label: t('common.button.decline'), startIcon: <CloseIcon />, onClick: handleDeclineOffer });
        } else if (direction === 'incoming' && type === 'combiProduct') {
          newActions.push({ label: t('common.button.confirm'), startIcon: <CheckIcon />, onClick: handleConfirmCombiProduct });
          newActions.push({ label: t('common.button.decline'), startIcon: <CloseIcon />, onClick: handleDeclineCombiProduct });
        }
        break;
      case 'outgoing':
        if (direction === 'outgoing' && type !== 'combiProduct') {
          newActions.push({ label: t('common.button.withdraw'), startIcon: <DeleteIcon />, onClick: handleWithdrawOffer });
        }
        break;
      case 'bookmarks':
        newActions.push({ label: t('common.button.linkup'), startIcon: <CheckIcon />, onClick: handleLinkupBookmark });
        newActions.push({ label: t('common.button.remove'), startIcon: <BookmarkMarkedIcon />, onClick: handleRemoveBookmark });
        break;
      case 'matches':
        if (item?.type === 'solution') {
          newActions.push({ label: t('common.button.cooperate'), startIcon: <AddIcon />, onClick: handleStartCombiProduct });
        }
        break;
      case 'search':
        newActions.push({
          label: t('common.button.linkup'), startIcon: <CheckIcon />, onClick: handleLinkup
        });
        newActions.push({ label: t('common.button.bookmark'), startIcon: <BookmarkIcon />, onClick: handleBookmark });
        break;
      case 'preview':
        newActions.push({ label: t('common.button.bookmark'), startIcon: <BookmarkIcon />, onClick: handleLinkupBookmark });
        break;
      case 'externalsearch':
        newActions.push({ label: t('common.button.bookmark'), startIcon: <BookmarkIcon />, onClick: handleLinkupBookmark });
        break;
      case 'searchforproject':
        newActions.push({ label: t('common.button.linkup'), startIcon: <CheckIcon />, onClick: handleLinkup });
        newActions.push({ label: t('common.button.bookmark'), startIcon: <BookmarkIcon />, onClick: handleBookmark });
        break;
      case 'matching':
        newActions.push({ label: t('common.button.linkup'), startIcon: <CheckIcon />, onClick: handleLinkup });
        newActions.push({ label: t('common.button.bookmark'), startIcon: <BookmarkIcon />, onClick: handleBookmark });
        newActions.push({ label: t('common.button.decline'), startIcon: <CloseIcon />, onClick: handleBlacklistSuggestion });
        break;
      case 'request':
        if (type === 'combiProduct') {
          newActions.push({ label: t('common.button.confirm'), startIcon: <CheckIcon />, onClick: handleConfirmCombiProduct });
          newActions.push({ label: t('common.button.decline'), startIcon: <CloseIcon />, onClick: handleDeclineCombiProduct });
        } else {
          newActions.push({ label: t('common.button.confirm'), startIcon: <CheckIcon />, onClick: handleConfirmOffer });
          newActions.push({ label: t('common.button.decline'), startIcon: <CloseIcon />, onClick: handleDeclineOffer });
        }
        break;
      case 'blacklist':
        newActions.push({ label: t('common.button.linkup'), startIcon: <CheckIcon />, onClick: handleLinkupBlacklisted });
        newActions.push({ label: t('common.button.bookmark'), startIcon: <BookmarkIcon />, onClick: handleBookmarkBlacklisted });
        break;
      default:
        break;
    }

    return newActions;
  };

  useEffect(() => {
    setActions(generateCardActions());
  }, []);

  useEffect(() => {
    if (actions.length > 2) {
      setOverflowActions(actions.slice(2));
    }
  }, [actions]);

  let logoSrc;

  if (logo) {
    logoSrc = logo;
  } else if (company && company.logo) {
    logoSrc = company.logo;
  } else {
    logoSrc = PlaceholderImage;
  }

  const score = Math.floor(item?.featureScore);

  const getStatusIcon = () => {
    switch (status) {
      case 10:
        return <CheckIcon fontSize="small" />;
      case 20:
        return <ReadIcon fontSize="small" />;
      default:
        return <CheckIcon fontSize="small" />;
    }
  };

  const getStatusLabel = () => {
    switch (status) {
      case 10:
        return 'ungelesen';
      case 20:
        return 'gelesen';
      case 30:
        return 'in Progress';
      case 40:
        return 'Pending';
      default:
        return '';
    }
  };

  const handleChipClick = () => {
    window.open(url, '_blank', 'norefferer');
  };

  return (
    <MuiCard sx={{
      height: '100%', display: 'flex', flexDirection: 'column', borderLeft: 'solid', borderColor: colorType(type, isCombiProduct), borderWidth: 4
    }}
    >
      {page !== 'search' && page !== 'companydetails' && page !== 'preview' && page !== 'edit-singleproduct' && (
      <CardHeader
        page={page}
        itemName={item?.name}
        score={score}
        newRefModels={newRefModels}
        isCombiProduct={isCombiProduct}
        type={item?.type}
        owner={owner}
        status={status}
      />
      )}
      <Tabs value={tabValue} onChange={tabChange} scrollButtons="auto">
        <Tab
          label="Allgemein"
          {...a11yProps(0)}
          disableRipple
          sx={{
            ml: 2, paddingX: 0, mr: 2, textTransform: 'capitalize', alignItems: 'flex-start', minWidth: '70px', fontWeight: 'bold',
          }}
        />
        <Tab
          label="Kategorien"
          {...a11yProps(1)}
          disableRipple
          sx={{
            paddingX: 0, mr: 2, textTransform: 'capitalize', alignItems: 'flex-start', minWidth: '70px', fontWeight: 'bold',
          }}
        />
        <Tab
          disabled={page === 'externalsearch' || page === 'preview'}
          label="Kontaktperson"
          {...a11yProps(2)}
          disableRipple
          sx={{
            paddingX: 0, mr: 2, textTransform: 'capitalize', alignItems: 'flex-start', minWidth: '70px', fontWeight: 'bold',
          }}
        />
        {isCombiProduct === true && (
        <Tab
          label="Zusammensetzung"
          {...a11yProps(3)}
          sx={{
            paddingX: 0, mr: 2, textTransform: 'capitalize', alignItems: 'flex-start', minWidth: '70px', fontWeight: 'bold',
          }}
        />
        )}
        { offerMessage && (
        <Tab
          label="Nachricht"
          {...a11yProps(4)}
          sx={{
            paddingX: 0, mr: 2, textTransform: 'capitalize', alignItems: 'flex-start', minWidth: '70px', fontWeight: 'bold',
          }}
        />
        )}
        { page === 'matching' && newRefModels && (
        <Tab
          label="Referenzmodelle"
          {...a11yProps(5)}
          sx={{
            paddingX: 0, mr: 2, textTransform: 'capitalize', alignItems: 'flex-start', minWidth: '70px', fontWeight: 'bold',
          }}
        />
        )}
      </Tabs>
      <Divider />
      {/* Allgemein */}
      <TabPanel value={tabValue} index={0}>
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
          {(isCombiProduct === true) ? (
            <AvatarGroup total={combiproductCompanies.length + 1}>
              <Avatar src={logoSrc} style={{ width: 70, height: 70, border: '1px solid #000' }} />
              {/* {combiproductCompanies?.map((comp: GenericObject) => (
                <Avatar src={comp?.company?.logo ?? PlaceholderImage} style={{ width: 70, height: 70, border: '1px solid #000' }} />
              ))} */}
            </AvatarGroup>
          )
            : (
              <Avatar
                src={logoSrc}
                sx={{
                  width: 70, height: 70, border: '1px solid #000'
                }}
              />
            )}
          <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }} pb={3}>
            <Box sx={{ ml: 2 }}>
              <Typography variant="h6" component={isClickable ? 'a' : 'span'} onClick={isClickable ? () => goToCompanyProfile(company?.id, navigate) : undefined}>
                {company?.name}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ color: 'text.secondary' }}>{company?.companyType?.label}</Typography>
              </Box>
              {company?.city && (
              <Typography>
                {company?.city}
              </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Typography onClick={handleToggle} variant="h5" fontWeight="bold" lineHeight={1} marginBottom={1} marginTop="auto">
          {name}
        </Typography>
        <Typography onClick={handleToggle} fontSize={12} color={colorType(type, isCombiProduct)} fontWeight="bold" lineHeight={1} marginBottom={1}>
          {typename()}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" letterSpacing="0.5px" marginBottom={5} style={{ fontSize: 14 }}>
            {summary}
          </Typography>
        </Box>
        {url && (
          <Chip clickable label="zur Produktwebseite" icon={<ExternalLinkIcon />} onClick={handleChipClick} />
        )}
      </TabPanel>
      {/* Kateogrien */}
      <TabPanel value={tabValue} index={1}>
        <CategoriesTabs {...props} />
      </TabPanel>
      {/* Kontakt */}
      <TabPanel value={tabValue} index={2}>
        {company?.contactPersons && (
          <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }} pb={5}>
            <Avatar src={(company?.contactPersons[0]?.contactPersonImage) ?? PlaceholderImage} sx={{ width: 70, height: 70, border: '1px solid #000' }} />
            <Box>
              <Typography variant="h4" fontWeight="bold" marginLeft={2}>
                {company?.contactPersons[0]?.firstname?.concat(' ', company?.contactPersons[0]?.lastname)}
              </Typography>
              <Box sx={{ display: 'flex', ml: 2, alignItems: 'center' }}>
                <Typography sx={{ color: 'text.secondary' }}>{company?.contactPersons[0]?.jobTitle}</Typography>
              </Box>
              {company?.contactPersons && (
              <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2 }}>
                <Typography component="a" href={`mailto:${company?.contactPersons && company?.contactPersons[0]?.email}`}>
                  {company?.contactPersons && company?.contactPersons[0]?.email}
                </Typography>
                <Typography style={{ whiteSpace: 'pre-wrap' }}>{(company?.contactPersons[0]?.email && company?.contactPersons[0].phoneNumber) && (' | ')}</Typography>
                <Typography component="a" href={`tel:${company?.contactPersons[0]?.phoneNumber}`}>
                  {company?.contactPersons[0]?.phoneNumber}
                </Typography>
              </Box>
              )}
            </Box>
          </Box>
        )}
      </TabPanel>
      {/* Nachricht */}
      {offerMessage && (
        <TabPanel value={tabValue} index={3}>
          <Box sx={{
            display: 'flex', alignItems: 'center', mb: 1
          }}
          >
            <Typography variant="body2" fontWeight={status === 10 && direction === 'incoming' ? 'bold' : 'inherit'}>
              {`Nachricht vom: ${getDateFromUnixTimestamp(offerCreated)}`}
            </Typography>
            <Tooltip title={getStatusLabel()}>
              {getStatusIcon()}
            </Tooltip>
            {status === 10 && (
            <Chip label="als gelesen markieren" onClick={handleRead} size="small" />
            )}
          </Box>
          <Typography variant="body2" fontSize={14}>{offerMessage}</Typography>
        </TabPanel>
      )}
      {/* Zusammensetzung */}
      {isCombiProduct === true && (
        <TabPanel value={tabValue} index={3}>
          {buildCombiproductSolutionList(
            combiproductCompanies, company
          ).map((c) => (
            <Box
              sx={{
                mt: 0.5, mb: 3, display: 'flex', alignItems: 'center'
              }}
            >
              <Avatar src={c?.logo ?? PlaceholderImage} sx={{ width: 70, height: 70, border: '1px solid #000' }} />
              <Box style={{ flexGrow: 1, marginLeft: 20 }}>
                <Typography fontSize={8} color="black" fontWeight="600" letterSpacing="1px" sx={{ textTransform: 'uppercase' }}>{c.type}</Typography>
                <Typography variant="h5" color="black" fontWeight="bold" lineHeight={1} marginTop={1.5} marginBottom={1.5}>{c.productName}</Typography>
                <Typography fontSize={12} color="black" fontWeight="600" letterSpacing="0.5px">{c.companyName}</Typography>
              </Box>
            </Box>
          ))}
        </TabPanel>
      )}
      {/* Refferenzmodell  */}
      { newRefModels && (
        <TabPanel value={tabValue} index={isCombiProduct === true ? 4 : 3}>
          {newRefModels.map((data: any) => (
            <Chip
              size="small"
              sx={{
                ml: 0,
                mr: 1,
                mb: 1,
                fontSize: 12,
              }}
              label={data.name}
              key={`reference_${data.id}`}
            />
          ))}
        </TabPanel>
      )}
      { actions.length > 0 && (
      <Box sx={{ mt: 'auto' }}>
        <Divider sx={{ height: '1px' }} />
        <CardActions sx={{
          py: { xs: 2, sm: 2 }, px: { xs: 2, sm: 2 }, alignContent: 'center'
        }}
        >
          {actions.slice(0, 2).map((action, index) => (
            <Button
              key={`${action.label}_${index + 1}`}
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                mr: 2, px: 2.5, py: 0.5, flexWrap: 'wrap'
              }}
              {...action}
            >
              {action.label}
            </Button>
          ))}
          {actions.length > 2 && (
          <HoverMenu
            overflowActions={overflowActions}
          />
          )}
        </CardActions>
      </Box>
      )}
    </MuiCard>
  );
};

export default DetailCard;
