import { FC, ReactElement } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

import { useAppStore } from '../../state';

import CloseX from '../ui/CloseX';

const FlexibleDialog:FC = ():ReactElement => {
  const { dialogData } = useAppStore((state:GenericObject) => state);
  const handleClose = () => useAppStore.setState({ dialogData: null });

  if (!dialogData) return <></>;

  const {
    maxWidth, fullWidth, onClose, sx, title, contentItems, actionItems
  } = dialogData;

  const handleOnClick = (onClick:any) => {
    if (onClick) onClick();
    handleClose();
  };

  return (
    <Dialog
      open={!!dialogData}
      maxWidth={maxWidth}
      fullWidth={fullWidth || true}
      onClose={onClose || handleClose}
      sx={sx}
    >
      <DialogTitle sx={title.sx} style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }}>{title.text}</DialogTitle>
      <CloseX onClose={handleClose} />
      <DialogContent sx={{ color: 'black', py: 2 }}>
        {
          contentItems.map((content:any) => (
            <Typography
              variant={content.variant || 'body1'}
              sx={content.sx}
              style={{ fontSize: '1rem', whiteSpace: 'pre-line' }}
              key={content.text.substring(0, 10)}
            >
              {content.text}
            </Typography>
          ))
        }
      </DialogContent>
      <DialogActions sx={{ pt: 2, pb: 3, px: 3 }}>
        {
          actionItems.map((action:any) => (
            <Button
              variant={action.variant || 'contained'}
              color={action.color || 'primary'}
              onClick={() => handleOnClick(action.onClick)}
              sx={{
                mr: 2, px: 2.5, py: 0.5, flexWrap: 'wrap'
              }}
              style={{ marginLeft: 24 }}
              key={action.text.substring(0, 2)}
            >
              {action.text}
            </Button>
          ))
        }
      </DialogActions>
    </Dialog>
  );
};

export default FlexibleDialog;
