/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  CardContent, Box, AvatarGroup, Avatar, Typography, Chip, CardActionArea as MuiCardActionArea, Link, Grid, Button
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BusinessOutlined as CompanyIcon,
  LocationOnOutlined as PinIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { colorType, goToCompanyProfile, buildCombiproductSolutionList } from '../../utils';
import PlaceholderImage from '../../assets/images/placeholder_company.png';
import OwnerImg from '../../assets/images/placeholder_person.png';
import CategoriesTabs from '../app/CategoriesTabs';

export type ICardActionAreaProps = {
 isDetailView: boolean;
 handleOpenDetailCard: (tabValue: string) => void;
 open: boolean;
 type: CardType;
 logo: string;
 company: any;
 combiproductCompanies: any;
 isCombiProduct: boolean;
 owner: boolean;
 name: string;
 summary: string;
 status: number;
 page: string;
 direction: string;
 mainIndustries: [];
}

const CardActionArea: React.FC<ICardActionAreaProps> = (props: ICardActionAreaProps) => {
  const {
    isDetailView,
    handleOpenDetailCard,
    open,
    type,
    logo,
    company,
    combiproductCompanies,
    isCombiProduct,
    summary,
    name,
    page,
    mainIndustries
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const typename = () => {
    switch (type) {
      case 'solution':
        if (isCombiProduct === true) {
          return t('card.type.combiproduct');
        }
        return t('card.type.singleproduct');
      case 'combiProduct':
        return t('card.type.combiproduct');
      case 'project':
        return t('card.type.application');
      default:
        return t('card.type.typeMissing');
    }
  };

  const isClickable = page !== 'preview' && page !== 'companydetails' && page !== 'externalsearch' && page !== 'edit-singleproduct';

  const renderCardContent = () => (
    <CardContent sx={{
      display: 'flex', alignSelf: 'flex-start'
    }}
    >
      <Box
        sx={{
          display: 'flex', flexDirection: 'column'
        }}
      >
        <Box
          onClick={() => handleOpenDetailCard('Allgemein')}
          sx={{
            display: 'flex', flexDirection: 'row', mb: 1
          }}
        >
          {(isCombiProduct === true) ? (
            <AvatarGroup total={combiproductCompanies.length + 1} onClick={() => handleOpenDetailCard('Zusammensetzung')}>
              <Avatar src={logo} style={{ width: 70, height: 70, border: '1px solid #000' }} />
              {/* {combiproductCompanies?.map((comp: GenericObject) => (
                <Avatar src={comp?.company?.logo ?? PlaceholderImage} style={{ width: 70, height: 70, border: '1px solid #000' }} />
              ))} */}
            </AvatarGroup>
          )
            : (
              <Avatar
                src={logo}
                sx={{
                  width: 70, height: 70, border: '1px solid #000'
                }}
              />
            )}
          <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }} pb={3}>
            <Box sx={{ ml: 2 }}>
              <Typography variant="h6" component={isClickable ? 'a' : 'span'} sx={{ textDecoration: isClickable ? 'underline' : 'none' }} onClick={isClickable ? () => goToCompanyProfile(company?.id, navigate) : undefined}>
                {company?.name}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ color: 'text.secondary' }}>{company?.companyType?.label}</Typography>
              </Box>
              {company?.city && (
                <Typography>
                  {company?.city}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box onClick={() => handleOpenDetailCard('Allgemein')}>
          <Typography variant="h5" fontWeight="bold" lineHeight={1} marginBottom={1} marginTop="auto">
            {name}
          </Typography>
          <Typography fontSize={12} color={colorType(type, isCombiProduct)} fontWeight="bold" lineHeight={1} marginBottom={1}>
            {typename()}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" letterSpacing="0.5px" marginBottom={5} style={{ fontSize: 14 }}>
              {isDetailView || summary.length <= 150 ? summary : `${summary.slice(0, 150)}...`}
            </Typography>
            {!isDetailView && summary.length > 150 && (
            // <Link onClick={() => handleOpenDetailCard('Allgemein')}>
            //   {t('common.button.more')}
            // </Link>
            <Button variant="text" size="small" onClick={() => handleOpenDetailCard('Allgemein')}>
              {t('common.button.more')}
            </Button>
            )}
          </Box>
        </Box>
        {(isDetailView === true && isCombiProduct === true && page === 'portfolio') && (
          <>
            <Typography variant="h6" fontWeight="bold">Zusammensetzung</Typography>
            {buildCombiproductSolutionList(
              combiproductCompanies, company
            ).map((c) => (
              <Box
                sx={{
                  mt: 0.5, mb: 3, display: 'flex', alignItems: 'center'
                }}
              >
                <Avatar src={c?.logo ?? PlaceholderImage} sx={{ width: 70, height: 70, border: '1px solid #000' }} />
                <Box style={{ flexGrow: 1, marginLeft: 20 }}>
                  <Typography fontSize={8} color="black" fontWeight="600" letterSpacing="1px" sx={{ textTransform: 'uppercase' }}>{c.type}</Typography>
                  <Typography variant="h5" color="black" fontWeight="bold" lineHeight={1} marginTop={1.5} marginBottom={1.5}>{c.productName}</Typography>
                  <Typography fontSize={12} color="black" fontWeight="600" letterSpacing="0.5px">{c.companyName}</Typography>
                </Box>
              </Box>
            ))}
          </>

        )}
        <Box onClick={() => handleOpenDetailCard('Kategorie')} sx={{ alignSelf: 'flex-start', cursor: 'pointer' }}>
          {!isDetailView && mainIndustries && mainIndustries.map((res: any) => (
            <Chip
              size="small"
              sx={{
                ml: 0,
                mr: 1,
                mb: 1,
                fontSize: 12,
              }}
              label={`${res.name}`}
              variant="filled"
              key={`cate_${res.id}`}
            />
          ))}
          {isDetailView === true && (
          <Grid container spacing={{ sm: 3, md: 4 }}>
            <Grid item xs={12} lg={12}>
              <CategoriesTabs {...props} />
            </Grid>
          </Grid>
          )}
          { isDetailView === true && company.contactPersons && (
          <Box
            sx={{
              mt: 0.5, mb: 1, display: 'flex', alignItems: 'center'
            }}
          >
            <Box component="img" src={company?.contactPersons[0]?.contactPersonImage ?? OwnerImg} alt="contact person image" borderRadius="100px" flexShrink="0" alignSelf="center" sx={{ width: 70, height: 70, objectFit: 'cover' }} />
            <Box style={{ flexGrow: 1, marginLeft: 20, maxWidth: '50%' }}>
              <Typography fontSize={12} color="black" letterSpacing="0.5px" marginBottom={1}>
                {company?.contactPersons[0]?.title || ''}
                {company?.contactPersons[0]?.firstname || ''}
                {' '}
                {company?.contactPersons[0]?.lastname || ''}
              </Typography>
              <Typography fontSize={12} color="black" letterSpacing="0.5px" marginBottom={1}>{company?.contactPersons[0]?.jobTitle || ''}</Typography>
              <Typography fontSize={12} color="black" letterSpacing="0.5px" marginBottom={1}>{company?.contactPersons[0]?.phoneNumber || ''}</Typography>
              <Typography fontSize={12} color="black" letterSpacing="0.5px" marginBottom={1}>
                <Link href={`mailto:${company?.contactPersons[0]?.email}`}>
                  {company?.contactPersons[0]?.email || ''}
                </Link>
              </Typography>
            </Box>
          </Box>
          )}
        </Box>
      </Box>
    </CardContent>
  );

  return (
    <>
      {isDetailView ? (
        renderCardContent()
      ) : (
        <MuiCardActionArea
          sx={{
            height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
          }}
          onClick={(e) => e.currentTarget === e.target && handleOpenDetailCard('Allgemein')}
        >
          {renderCardContent()}
        </MuiCardActionArea>
      )}
    </>

  );
};

export { CardActionArea };
