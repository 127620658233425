import { ReactElement } from 'react';
import { Drawer } from '@mui/material';
import { useAppStore } from '../../../state';
import Navigation from './Navigation';
import { useWindowSize } from '../../../hooks';

const CustomDrawer = ():ReactElement => {
  const isOpened = useAppStore((state:GenericObject) => state.drawerIsOpened);
  const toggleIsOpened = useAppStore((state:GenericObject) => state.appToggleDrawerIsOpened);
  const [screenWidth] = useWindowSize();
  const isMobile = screenWidth < 768;

  const sxClosed:GenericObject = {
    '& .MuiDrawer-paper': {
      mt: '60px', width: '70px', overflowX: 'hidden', backgroundColor: 'background.paper',
    }
  };

  const sxOpened:GenericObject = {
    '& .MuiDrawer-paper': {
      mt: '60px', width: '280px', backgroundColor: 'background.paper',
    }
  };

  return (
    <Drawer
      sx={isOpened ? sxOpened : sxClosed}
      variant={isMobile ? 'temporary' : 'permanent'}
      open={isOpened}
      onClose={() => toggleIsOpened(!isOpened)}
    >
      <Navigation />
    </Drawer>
  );
};

export default CustomDrawer;
