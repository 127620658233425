import {
  Box, IconButton, Menu, MenuItem
} from '@mui/material';
import React, { useState } from 'react';
import { MoreVertOutlined as MoreIcon } from '@mui/icons-material';

type HoverMenuProps = {
 overflowActions: CardAction[];
}
/**
 * HoverMenu Component
 *
 * This component renders a button with a dropdown menu.
 * It takes an array of actions and renders them as menu items.
 *
 * @param {object} props - The component props.
 * @param {Array} props.overflowActions - An array of CardAction objects.
 * @returns {JSX.Element} - The rendered component.
 */
const HoverMenu: React.FC<HoverMenuProps> = (props: HoverMenuProps) => {
  const {
    overflowActions
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ ml: 'auto' }}>
      <IconButton size="small" onClick={handleClick}>
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {overflowActions.map((action, index) => (
          <MenuItem key={`${action.label}_${index + 1}`} onClick={action.onClick} disabled={action.disabled} sx={{ fontSize: 12 }}>
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default HoverMenu;
