import { FC, ReactElement, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Typography, Button, Grid, Container
} from '@mui/material';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import LandingImage from '../components/partials/LandingImage';

const LandingPage: FC = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // check token in localStorage. If exists --> try to login automatically
  const token = localStorage.getItem('token');
  if (token) {
    setTimeout(() => {
      navigate('/app/startscreen');
    }, 50);
    return <></>;
  }

  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: 'Landingpage',
      href: `${process.env.MATOMO_TARGE_BASE_URL}`,
    });
  }, []);

  return (
    <Container style={{ height: '100%' }}>
      <Grid
        container
        height="100%"
        columnSpacing={{ xs: 1, sm: 2, md: 10 }}
        rowSpacing={{ xs: 4, sm: 5, md: 3 }}
        paddingTop={2}
        // alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <LandingImage />
        </Grid>
        <Grid item xs={12} md={6} mt={2}>
          <Typography variant="h1" gutterBottom>{ t('landing.header') }</Typography>
          <Typography color="black" style={{ marginBottom: '20px' }} gutterBottom dangerouslySetInnerHTML={{ __html: t('landing.text.paragraph1') }} />
          {/* <Typography color="black" gutterBottom dangerouslySetInnerHTML={{ __html: t('landing.text.paragraph2') }} /> */}
          {process.env.REACT_APP_ENV === 'STAGE' && (
          <Typography variant="h1" color="primary" gutterBottom>STAGE VERSION</Typography>
          )}
          <div style={{ marginTop: '20px' }}>
            <Button
              sx={{
                mb: 1, px: 1.5, py: 0.7, mr: 2
              }}
              size="large"
              component={Link}
              to="/register"
              variant="contained"
            >
              { t('landing.button.newProfile') }
            </Button>
            <Button
              sx={{
                mb: 1, px: 1.5, py: 0.7
              }}
              size="large"
              color="secondary"
              component={Link}
              to="/login"
              variant="contained"
            >
              { t('landing.button.login') }
            </Button>
          </div>
          <Grid marginTop={7}>
            <Typography component="div">
              <h3 style={{ marginBottom: '0', color: 'black' }}>
                { t('landing.subheader') }
              </h3>
              <p style={{ marginTop: '5px', color: 'black' }}>
                { t('landing.text.paragraph3') }
              </p>
            </Typography>
            <Button
              sx={{
                mb: 1, px: 1.5, py: 0.7
              }}
              size="large"
              component={Link}
              to="/preview"
              variant="contained"
            >
              { t('landing.button.preview') }
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LandingPage;
