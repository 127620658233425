import {
  FC, ReactElement, useRef,
} from 'react';

import {
  Box, Typography, Grid, Button
} from '@mui/material';

import { useNavigate } from 'react-router';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useTranslation } from 'react-i18next';
import JsPDF from 'jspdf';
import { useProductStore } from '../../state';
import CompareTable from './CompareTable';

const ComparisonMain: FC = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    bookmarks,
    compareList,
  } = useProductStore((state: GenericObject) => state);
    /* eslint-disable react/jsx-props-no-spreading */

  function getCompareProducts() {
    const array = bookmarks.filter((item:any) => compareList.includes(item.itemId));
    return [...new Map(array.map((el:any) => [el.itemId, el])).values()];
  }

  function generatePDF() {
    const report = new JsPDF('portrait', 'pt', 'a4');
    report.html(document.getElementsByClassName('compare') as unknown as HTMLElement).then(() => {
      report.save('vergleich.pdf');
    });
  }

  const pdfRef = useRef(null);

  const handleDownload = () => {
    const content = pdfRef.current;

    const doc = new JsPDF();
    if (content) {
      doc.html(content, {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        callback(doc) {
          doc.save('vergleich.pdf');
        }
      });
    } else {
      console.log(pdfRef);
    }
  };

  return (
    <Box>
      <Button
        onClick={() => navigate(-1)}
        sx={{
          mb: 1, px: 1.5, py: 0.7
        }}
        startIcon={<ChevronLeftIcon />}
      >
        {t('common.button.back')}
      </Button>
      {/* <Button
        onClick={() => handleDownload()}
      >
        {' '}
        PDF
      </Button> */}
      <Typography variant="h1" color="black" fontWeight="bold" marginBottom={{ xs: 2, lg: 3 }}>Vergleich</Typography>
      <Grid container spacing={{ xs: 3, sm: 4, md: 5 }}>
        <Grid item xs={12} sm={12}>
          <CompareTable productDataList={getCompareProducts()} />
        </Grid>
      </Grid>
    </Box>
  );
};
export default ComparisonMain;
