import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, Link, Typography
} from '@mui/material';

import { useAppStore } from '../../state';

const EventNotfication: FC = (): ReactElement => {
  const app = useAppStore((state:GenericObject) => state);
  const {
    dataEventNotification, showEventNotification, appDiscardEventNotification, appToggleEventNotification
  } = app;

  const { t } = useTranslation();

  // no data available
  if (!dataEventNotification) return <></>;

  const {
    title, message, url, active
  } = dataEventNotification;

  // Event notification must be set to active in Wordpress, enabled in the store and not locked in localeStorage
  if (!active || !showEventNotification) return <></>;

  const item = { title, subtitle: message, src: url };

  const handleDiscard = () => appDiscardEventNotification(false);
  const handleHide = () => appToggleEventNotification();

  return (
    <Box sx={{
      position: 'absolute', top: '100%', right: '0', backgroundColor: 'primary.main', minWidth: '320px', px: 3, py: 3
    }}
    >
      <Box>
        <Typography variant="h6" sx={{ color: 'common.white', mb: 1 }}>{ item.title }</Typography>
        <Typography sx={{ color: 'common.white', fontSize: '12px', mb: 1.5 }}>{ item.subtitle }</Typography>
        <Link sx={{ color: 'common.white' }} href={item.src} target="_blank">
          &gt;
          {' '}
          { t('common.text.moreInfos') }
        </Link>
      </Box>
      <Box sx={{ textAlign: 'right', mt: 2 }}>
        <Button variant="text" sx={{ color: 'common.white', p: 0 }} onClick={handleHide}>{ t('common.button.close') }</Button>
        <Button variant="text" sx={{ color: 'common.white', p: 0, ml: 2 }} onClick={handleDiscard}>{ t('common.button.discard') }</Button>
      </Box>
    </Box>
  );
};

export default EventNotfication;
