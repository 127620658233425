/* eslint-disable react/jsx-props-no-spreading */
import {
  FC, ReactElement, useState, useEffect
} from 'react';

import { useTranslation } from 'react-i18next';

import {
  Box, Typography, Button, Grid, Pagination, IconButton, ButtonGroup, Autocomplete, TextField, styled, Paper, Skeleton
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useLocation } from 'react-router';
import Card from '../ui/Card';

import { useAppStore, usePaginationStore, useProductStore } from '../../state';
import SkeletonCard from '../ui/SkeletonCard';
import MatchingLinkupDialog from './MatchingLinkupDialog';

interface FilteredSuggestionsLists {
  solutionSuggestions: any[];
  projectSuggestions: any[];
  combiProductSuggestions: any[];
}

const MatchingMain: FC = (): ReactElement => {
  const { t } = useTranslation();
  const location = useLocation();
  const locationState = (location.state as {selectionId?: number}) || {};

  const { selectionId = null } = locationState;

  const [openDialogLinkup, setOpenDialogLinkup] = useState(false);
  const [linkupItem, setLinkupItem] = useState({});
  const [productName, setProductName] = useState('');

  const [options, setOptions] = useState<Option[]>([]);

  const [filter, setFilter] = useState<'all' | 'solution' | 'combiProduct' | 'project'>('all');
  const [productFilterValue, setProductFilterValue] = useState<Option>();

  const [filteredLists, setFilteredLists] = useState<FilteredSuggestionsLists>({
    solutionSuggestions: [],
    projectSuggestions: [],
    combiProductSuggestions: [],
  });

  const { showSkeleton } = useAppStore((appState:GenericObject) => appState);
  const {
    singleProducts,
    combiProducts,
    projects,
    suggestions,
    prodBookmarkSuggestion,
    prodBlacklistSuggestion,
    prodConfirmOffer,
    prodDeclineOffer,
    prodConfirmCombiProduct,
    prodDeclineCombiProduct

  } = useProductStore((prodState:GenericObject) => prodState);

  const {
    init,
    jump,
    countItems,
    currentPage,
    maxPage,
    paginatedData,
  } = usePaginationStore();

  const filterAll = 'all';
  const filterSolution = 'solution';
  const filterProject = 'project';
  const filterCombiProduct = 'combiProduct';

  const handleBookmarkSuggestion = async (id: number) => {
    const succes : boolean = await prodBookmarkSuggestion(id);

    if (succes) {
      await useProductStore.setState((prevState: any) => ({
        ...prevState,
        suggestions: prevState.suggestions.filter((item: any) => item.id !== id)
      }));
    }
  };

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light
  }));

  const GroupItems = styled('ul')({
    padding: 0,
  });

  const getFilterValue = () => {
    if (options && productFilterValue) {
      // When there are Suggestions and
      if (suggestions?.length > 0) {
        const solutionData: any = suggestions.filter((item : any) => item.type === 'solution' && item.isCombiProduct === false && (productFilterValue === options[0] || item.item.id === productFilterValue.id));
        const projectData: any = suggestions.filter((item : any) => item.type === 'project' && (productFilterValue === options[0] || item.item.id === productFilterValue.id));
        const combiProductData: any = suggestions.filter((item : any) => item.isCombiProduct === true && (productFilterValue === options[0] || item.item.id === productFilterValue.id));
        setFilteredLists(({
          solutionSuggestions: solutionData,
          projectSuggestions: projectData,
          combiProductSuggestions: combiProductData,
        }));
      }
    }
  };

  // Function to filter data based on specified value.
  // newValue specifies the type of filter to apply (all or specific type)
  // The filtered data is then passed to the init function for display update
  const filterChange = (newValue: any) => {
    if (options && productFilterValue) {
      setFilter(newValue);
      if (newValue === 'solution') {
        init(suggestions.filter((item : any) => (item.type === newValue && item.isCombiProduct === false) && (productFilterValue === options[0] || item.item.id === productFilterValue.id)), 6, 1);
      } else if (newValue === 'combiProduct') {
        init(suggestions.filter((item : any) => item.isCombiProduct === true && (productFilterValue === options[0] || item.item.id === productFilterValue.id)), 6, 1);
      } else if (newValue === 'project') {
        init(suggestions.filter((item : any) => item.type === 'project' && (productFilterValue === options[0] || item.item.id === productFilterValue.id)), 6, 1);
      } else {
        init(suggestions.filter((item : any) => (productFilterValue === options[0] || item.item.id === productFilterValue.id)), 6, 1);
      }
    }
  };

  useEffect(() => {
    const newProductOptions: Option[] = singleProducts.map((product: GenericObject) => ({ id: product.id, name: product.name, type: 'Produkt' }));
    const newCombiProductOptions: Option[] = combiProducts.map((item : GenericObject) => ({ id: item.id, name: item.name, type: 'Kombiprodukt' }));
    const newProjectOptions: Option[] = projects.filter((project: GenericObject) => project.status === 50).map((project: GenericObject) => ({ id: project.id, name: project.name, type: 'Gesuch' }));
    const allOption: Option[] = [{ id: 0, name: 'Alle' }];

    setOptions([...allOption, ...newProductOptions, ...newCombiProductOptions, ...newProjectOptions]);
  }, []);

  useEffect(() => {
    getFilterValue();
    switch (filter) {
      case filterSolution:
        init(suggestions.filter((item : any) => item.type === filterSolution && item.isCombiProduct === false), 6, 1);
        break;
      case filterCombiProduct:
        init(suggestions.filter((item : any) => item.type === filterCombiProduct || item.isCombiProduct === true), 6, 1);
        break;
      case filterProject:
        init(suggestions.filter((item : any) => item.type === filterProject), 6, 1);
        break;
      case filterAll:
        init(suggestions, 6, 1);
        break;
      default:
        init(suggestions, 6, 1);
        break;
    }
  }, [suggestions.length]);

  useEffect(() => {
    getFilterValue();
    filterChange(filter);
  }, [productFilterValue]);

  useEffect(() => {
    const newProductOptions: Option[] = singleProducts.map((product: GenericObject) => ({ id: product.id, name: product.name, type: 'Produkt' }));
    const newCombiProductOptions: Option[] = combiProducts.filter((item: GenericObject) => item.status === 50 && item.owner).map((item : GenericObject) => ({ id: item.id, name: item.name, type: 'Kombiprodukt' }));
    const newProjectOptions: Option[] = projects.filter((project: GenericObject) => project.status === 50).map((project: GenericObject) => ({ id: project.id, name: project.name, type: 'Gesuch' }));
    const allOption: Option[] = [{ id: 0, name: 'Alle' }];

    setOptions([...allOption, ...newProductOptions, ...newCombiProductOptions, ...newProjectOptions]);
  }, [suggestions.length]);

  useEffect(() => {
    if (options) {
      if (selectionId) {
        setProductFilterValue(options.find((op) => op.id === selectionId));
        window.history.replaceState({}, document.title);
      } else {
        setProductFilterValue(options[0]);
      }
    }
  }, [options]);

  const handleLinkup = (data: any, name: string) => {
    setLinkupItem(data);
    setProductName(name);
    setOpenDialogLinkup(!openDialogLinkup);
  };

  const handleInfo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('matching.info.header') },
      contentItems: [
        {
          text: t('matching.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  return (
    <>
      <MatchingLinkupDialog
        open={openDialogLinkup}
        onClose={() => setOpenDialogLinkup(false)}
        item={linkupItem}
        productname={productName}
      />
      <Box>
        <Box style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 12
        }}
        >
          <Typography variant="h1" fontWeight="bold">
            {t('matching.header')}
          </Typography>
          <IconButton size="large" onClick={handleInfo}><InfoOutlined /></IconButton>
        </Box>
        <Paper sx={{
          display: 'inline-flex', flexWrap: 'wrap', justifyContent: 'start', pt: 1, pl: 1, alignContent: 'center', flexDirection: 'row'
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            pr: 2
          }}
          >
            <Typography>Kategorie</Typography>
            <ButtonGroup>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  mb: 1, px: 1.5, py: 0.7
                }}
                style={{ opacity: filter === filterAll ? '1' : '0.6' }}
                onClick={() => filterChange(filterAll)}
              >
                { `${t('matching.filter.all')} ` }
                (
                { filteredLists.solutionSuggestions.length + filteredLists.combiProductSuggestions.length + filteredLists.projectSuggestions.length }
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  mb: 1, px: 1.5, py: 0.7
                }}
                style={{ opacity: filter === filterSolution ? '1' : '0.6' }}
                onClick={() => filterChange(filterSolution)}
              >
                { `${t('matching.filter.singleProducts')} ` }
                (
                {filteredLists.solutionSuggestions.length}
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  mb: 1, px: 1.5, py: 0.7
                }}
                style={{ opacity: filter === filterCombiProduct ? '1' : '0.6' }}
                onClick={() => filterChange(filterCombiProduct)}
              >
                { `${t('matching.filter.combiProducts')} ` }
                (
                {filteredLists.combiProductSuggestions.length}
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  mb: 1, px: 1.5, py: 0.7
                }}
                style={{ opacity: filter === filterProject ? '1' : '0.6' }}
                onClick={() => filterChange(filterProject)}
              >
                { `${t('matching.filter.requests')} ` }
                (
                {filteredLists.projectSuggestions.length}
                )
              </Button>
            </ButtonGroup>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            pr: 2
          }}
          >
            <Typography>Anzeigen für:</Typography>
            <Autocomplete
              id="productfilter"
              size="small"
              sx={{
                width: 300,
              }}
              options={options}
              value={productFilterValue || null}
              groupBy={(item: Option) => item.type ?? ''}
              getOptionLabel={(item: Option) => item.name}
              isOptionEqualToValue={(option, values) => values === undefined || option.id === values.id}
              onChange={(event: any, newValue) => {
                if (newValue) {
                  setProductFilterValue(newValue);
                }
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" inputProps={{ ...params.inputProps }} />}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
            />
          </Box>
        </Paper>
        { showSkeleton ? (
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }} paddingTop={{ sm: 3 }}>
            { [...Array(6)].map((e, i) => (
              <Grid item xs={12} lg={6} xl={4}>
                <SkeletonCard key={`skel_${i * 2}`} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }} paddingTop={{ sm: 3 }}>
            {paginatedData.map((data:any) => (
              <Grid item xs={12} lg={6} xl={4}>
                <Card
                  key={`card_${data.type}_${data.id}`}
                  handleBookmark={async () => handleBookmarkSuggestion(data.id)}
                  handleBlacklistSuggestion={() => prodBlacklistSuggestion(data.id)}
                  handleLinkup={() => handleLinkup(data, data.item.name)}
                  handleConfirmOffer={() => prodConfirmOffer(data.id)}
                  handleDeclineOffer={() => prodDeclineOffer(data.id)}
                  handleConfirmCombiProduct={() => prodConfirmCombiProduct(data.id)}
                  handleDeclineCombiProduct={() => prodDeclineCombiProduct(data.id)}
                  company={data?.item?.company}
                  isDetailView={false}
                  {...data}
                />
              </Grid>
            ))}
          </Grid>
        )}

        <Box sx={{ pt: 3 }}>
          {showSkeleton ? (
            <Skeleton width={120} height={20} />
          ) : (countItems > 0 && <Pagination count={maxPage} page={currentPage} onChange={(event, value1) => jump(value1)} />)}
          {(showSkeleton === false && countItems === 0) && (<Typography variant="h6">{t('common.text.noData')}</Typography>)}
        </Box>
      </Box>
    </>
  );
};
export default MatchingMain;
