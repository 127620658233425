import { FC, ReactElement } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import {
  AppBar, Toolbar, Link, Button, Box
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import logo from '../../../assets/images/logo.svg';

const Header: FC = ():ReactElement => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const handleRegister = () => navigate('/register');
  const handleLogin = () => navigate('/login');

  return (
    <AppBar sx={{ background: 'white', height: '60px' }}>
      <Toolbar>
        <Link underline="hover" color="primary" component={RouterLink} to="/">
          <img style={{ height: '50px' }} src={logo} alt="Logo IT2Match" />
        </Link>
        <Box sx={{ flexGrow: 1 }} />
        {location.pathname === '/externalsearch' && (
          <>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={handleRegister}
              sx={{
                fontWeight: 'bold',
                mt: 0,
                ml: 1,
                display: {
                  xs: 'none',
                  md: 'block'
                }
              }}
            >
              {t('common.button.register') }
            </Button>
            <Button
              size="large"
              color="primary"
              onClick={handleLogin}
              sx={{
                fontWeight: 'bold',
                mt: 0,
                ml: 1,
                display: {
                  xs: 'none',
                  md: 'block'
                }
              }}
            >
              {t('common.button.login') }
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};
export default Header;
