import { ReactElement } from 'react';

import {
  FormControlLabel,
  Checkbox,
  FormGroup
} from '@mui/material';

const Checkboxes = (props:GenericObject):ReactElement => {
  const {
    type, data, handleCheckbox
  } = props;

  const copyOfArray = [...data];
  const tempArray:any = [];

  copyOfArray.forEach((item:GenericObject) => {
    item.children
      .filter((child:GenericObject) => child.show && child.search)
      .forEach((child:GenericObject) => tempArray.push({ id: child.id, name: child.name, checked: child.checked }));
  });

  // eslint-disable-next-line no-nested-ternary
  tempArray.sort((a:GenericObject, b:GenericObject) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));

  return (
    <>
      <FormGroup row>
        {
        tempArray.map((item:GenericObject) => (
          <FormControlLabel
            key={item.id}
            sx={{
              width: '24%', fontSize: 14, overflowWrap: 'anywhere'
            }}
            control={<Checkbox id={item.id} data-type={type} checked={item.checked} onChange={() => { handleCheckbox(type, item.id); }} />}
            label={item.name}
          />
        ))
             }
      </FormGroup>
    </>
  );
};

export default Checkboxes;
