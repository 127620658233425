import {
  FC, ReactElement, useState, useEffect
} from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import {
  Button, Box, Typography, Container, Grid, Link,
} from '@mui/material';

// import { DefaultSerializer } from 'v8';
import { useUserStore, useAppStore } from '../state';

import {
  ruleFirstname,
  ruleLastname,
  ruleCompanyNotExists,
  ruleEmailAddressNotExists,
  rulePassword,
  rulePasswordConfirmation,
  ruleCompanyType,
  ruleCompanyCity,
} from '../rules';

import BackToStart from '../components/ui/BackToStart';
import FormInputText from '../components/ui/FormInputText';
import FormInputCheckbox from '../components/ui/FormInputCheckbox';
import FormSelect from '../components/ui/FormSelect';
import LandingImage from '../components/partials/LandingImage';

const Register: FC = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    userRegister: reg,
    userResendOptIn: resend,
    userOnboarding: onboard,
    onboardingData
  } = useUserStore((state:GenericObject) => state);
  const selections = useAppStore((state:GenericObject) => state.selections);

  const temp: any = {};
  const [registerData, setRegisterData] = useState(temp);
  const [hiddenRegister, setHiddenRegister] = useState(false);
  const [hiddenResend, setHiddenResend] = useState(true);
  const [image, setImage] = useState('');

  const {
    handleSubmit, control, getValues, setValue, reset, register
  } = useForm();

  // Get onboarding data if a token was submitted
  const { token } = useParams();
  useEffect(() => {
    if (token) onboard(token);
    useAppStore.getState().appGetSelections();
  }, []);

  useEffect(() => {
    const person = onboardingData.contactPersons[0] || [];
    // setValue('firstname', person.firstname, { shouldTouch: true });
    // setValue('lastname', person.lastname, { shouldTouch: true });
    // setValue('email', person.email, { shouldTouch: true });
    // setValue('companyName', onboardingData.name, { shouldTouch: true });
    reset({
      firstname: person.firstname,
      lastname: person.lastname,
      email: person.email,
      companyName: onboardingData.name,
    });
  }, [onboardingData]);

  const onSubmit = (data: GenericObject) => {
    const {
      firstname, lastname, email, companyName, password, confirmTOS, newsletter, zip, city, companyTypeId
    } = data;

    if (!confirmTOS) {
      const dialogData = {
        title: { text: t('common.error.problem') },
        contentItems: [{ text: t('common.error.TOSNotConfirmed') }],
        actionItems: [{ text: t('common.button.okUnderstood'), color: 'secondary' }],
      };
      useAppStore.setState({ dialogData });

      return false;
    }

    const formData = new FormData();
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('email', email);
    formData.append('contactPersonEmail', '');
    formData.append('companyName', companyName);
    formData.append('password', password);
    formData.append('zip', zip);
    formData.append('city', city);
    formData.append('companyTypeId', companyTypeId);
    // formData.append('newsletter', newsletter);
    formData.append('confirmTOS', confirmTOS);

    // Avatar image
    formData.append('file', image);
    formData.append('filename', `${nanoid(20)}.jpg`);

    const promise = reg({ data: formData });
    promise.then((success: boolean) => {
      if (success) {
        setRegisterData(data);
        setHiddenRegister(true);
        setHiddenResend(false);
      }
    });

    return true;
  };

  const handleResendOptIn = () => resend(registerData.email);

  const handleRegisterAgain = () => {
    setHiddenRegister(false);
    setHiddenResend(true);
  };

  const handleLink = () => {
    window.location.href = 'https://itwirtschaft.de/datenschutz-app/\'';
  };

  const handleStartpage = () => navigate('/');

  return (
    <>
      <Container style={{ height: '100%' }}>
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 10 }}
          rowSpacing={{ xs: 4, sm: 5, md: 3 }}
          paddingTop={2}
          // alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <LandingImage />
          </Grid>
          <Grid item xs={12} md={6} mt={2}>
            <BackToStart />
            <Typography variant="h2" gutterBottom>
              {t('register.header')}
            </Typography>
            <Typography sx={{ fontWeight: 'bold' }} gutterBottom hidden={hiddenRegister}>
              {t('register.text.paragraph1')}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '20px' }}>
              <Box hidden={hiddenRegister}>
                <FormInputText
                  name="firstname"
                  control={control}
                  ref={register}
                  defaultValue=""
                  label={t('common.input.firstName')}
                  rules={ruleFirstname}
                  autoComplete="given-name"
                />

                <FormInputText
                  name="lastname"
                  control={control}
                  ref={register}
                  defaultValue=""
                  label={t('common.input.lastName')}
                  rules={ruleLastname}
                  autoComplete="family-name"
                />

                <FormInputText
                  name="email"
                  control={control}
                  ref={register}
                  defaultValue=""
                  label={t('common.input.email')}
                  rules={ruleEmailAddressNotExists}
                  autoComplete="email"
                />

                <FormInputText
                  name="companyName"
                  control={control}
                  ref={register}
                  defaultValue=""
                  label={t('common.input.companyName')}
                  rules={ruleCompanyNotExists}
                  autoComplete="organization"
                />

                <FormSelect
                  name="companyTypeId"
                  data={selections.companyType}
                  control={control}
                  ref={register}
                  defaultValue=""
                  label={`${t('company.input.companyType')}`}
                  rules={ruleCompanyType}
                  required
                />

                <FormInputText
                  name="city"
                  control={control}
                  ref={register}
                  defaultValue=""
                  label="Stadt"
                  rules={ruleCompanyCity}
                  autoComplete="city"
                />

                <FormInputText
                  name="zip"
                  control={control}
                  ref={register}
                  defaultValue=""
                  label="Postleitzahl"
                  rules={ruleCompanyNotExists}
                  autoComplete="zip"
                />

                <FormInputText
                  name="password"
                  control={control}
                  ref={register}
                  label={t('common.input.password')}
                  type="password"
                  rules={rulePassword}
                  autoComplete="new-password"
                  helperText={`
                  erlaubte Zeichen: a-zA-Z0-9(!@#$%^&*)
                  muss mindestens ein Großbuchstaben (A-Z) enthalten,
                  muss mindestens ein Kleinbuchstaben (a-z) enthalten,
                  muss mindestens eine Ziffer (0-9) enthalten,
                  muss mindestens 8 Zeichen lang sein.`}
                />

                <FormInputText
                  name="passwordConfirm"
                  control={control}
                  ref={register}
                  label={t('common.input.confirmPassword')}
                  type="new-password"
                  rules={{ validate: (value: string) => rulePasswordConfirmation(value, getValues('password')) }}
                  autoComplete="new-password"
                />

                {/* <Typography
                  style={{
                    fontSize: 12,
                    marginBottom: 4,
                    marginLeft: 10
                  }}
                >
                  {t('common.input.profileImage')}
                </Typography>
                <RegistrationUploadAndDisplayImage
                  onChangeImage={setImage}
                  defaultImage={PlaceholderLogoPerson}
                />
                <Typography
                  style={{
                    fontSize: 12,
                    marginBottom: 20,
                    marginTop: 6,
                    marginLeft: 10
                  }}
                >
                  {t('common.input.imageTypeInfo')}
                </Typography> */}

                {/* <FormInputCheckbox
                  name="newsletter"
                  control={control}
                  label={t('register.newsletter')}
                /> */}
                <br />
                <FormInputCheckbox
                  name="confirmTOS"
                  control={control}
                  ref={register}
                  label={t('register.input.confirmTOS')}
                />
                <br />
                <Box sx={{ mt: 3, cursor: 'pointer' }}>
                  <Link
                    component={RouterLink}
                    to="/dataprotection"
                    target="_blank"
                  >
                    Zur Datenschutzerklärung
                  </Link>
                </Box>
                <br />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mb: 1, px: 1.5, py: 0.7
                  }}
                  style={{ marginTop: 20 }}
                >
                  {t('register.button.register')}
                </Button>
                <Typography style={{
                  marginBottom: 20, marginTop: 20
                }}
                >
                  {t('register.button.alreadyRegistered')}
                  &nbsp;
                  <Typography component={RouterLink} to="/login" style={{ color: '#B50900', fontWeight: 'bold' }}>
                    {t('common.button.loginHere')}
                  </Typography>
                </Typography>
              </Box>
              <Box hidden={hiddenResend}>
                <Typography variant="h3" mb={2}>{t('register.text.madeIt')}</Typography>
                <Typography mb={2}>
                  {t('register.text.optInSent', { variable: registerData.email })}
                </Typography>

                <div style={{ marginLeft: '-.5rem' }}>
                  <Button sx={{ m: 1 }} type="button" variant="contained" color="primary" component={RouterLink} to="/login">
                    {t('common.button.login')}
                  </Button>
                  <Button sx={{ m: 1 }} type="button" variant="contained" color="secondary" onClick={handleResendOptIn}>
                    {t('common.button.resendOptIn')}
                  </Button>
                </div>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Register;
