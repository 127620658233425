/* eslint-disable max-len */
import React, { ReactElement, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Box, AppBar, Toolbar, Button, IconButton, Link, Tooltip, Menu, MenuItem, Divider
} from '@mui/material';

import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Add as AddIcon,
  InfoOutlined as InfoIcon,
  HelpOutline as HelpOutlineIcon,
  CalendarToday as CalendarTodayIcon,
  FlashOn as FlashIcon,
  MoreVert as MoreIcon,
  DarkModeOutlined as DarkIcon,
  LightModeOutlined as LightIcon,
} from '@mui/icons-material';

import { useAppStore, useProductStore, useUserStore } from '../../../state';

import logo from '../../../assets/images/logo.svg';

import EventNotification from '../../app/EventNotfication';

const Header = ():ReactElement => {
  const app = useAppStore((state:GenericObject) => state);

  const isOpened = app.drawerIsOpened;
  const toggleIsOpened = app.appToggleDrawerIsOpened;
  const {
    appSetShowOnboarding,
    showEventNotification,
    discardEventNotification,
    appToggleEventNotification,
    appSwitchTheme,
    darkMode
  } = app;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const logout = useUserStore((state:GenericObject) => state.userLogout);

  const [anchorActionEl, setAnchorActionEl] = useState<null | HTMLElement>(null);
  const [anchorHelpEl, setAnchorHelpEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);

  const openAction = Boolean(anchorActionEl);
  const openHelp = Boolean(anchorHelpEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorActionEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleActionClose = (type:string) => {
    if (type === 'solution') {
      useProductStore.setState({ currentProductProject: null });
      navigate('/app/edit-singleproduct');
    }
    if (type === 'combiProduct') {
      useProductStore.getState().prodGetCategories();
      useUserStore.getState().userGetAccountData()
        .then(() => useProductStore.getState().prodGetNetworks());
      useProductStore.setState({ currentProductProject: null });
      navigate('/app/edit-combiproduct');
    }
    if (type === 'application') {
      useProductStore.setState({ currentProductProject: null });
      navigate('/app/edit-project');
    }

    setAnchorActionEl(null);
  };

  const handleHelpClose = (type:string) => {
    if (type === 'provider') {
      appSetShowOnboarding(true);
    }
    if (type === 'user') {
      appSetShowOnboarding(true);
    }

    setAnchorHelpEl(null);
  };

  const handleSwitchThemeMode = () => {
    appSwitchTheme(!darkMode);
  };

  const quickactionButtonSx:GenericObject = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    color: 'text.primary',
    borderRadius: '0',
  };

  let eventToggleButtonSx:GenericObject = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    color: 'text.primary',
    borderRadius: '0',
  };

  if (!discardEventNotification) {
    eventToggleButtonSx = {
      ...eventToggleButtonSx,
      backgroundColor: 'primary.main',
      color: 'common.white',
      '&:focus, &:hover': {
        backgroundColor: 'primary.main',
        color: 'common.white'
      }
    };
  }

  const handleLogout = () => {
    logout();
  };

  const handleHelp = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorHelpEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      id={mobileMenuId}
      keepMounted
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => handleHelpClose('provider')}>
        Onboarding Anbieter
      </MenuItem>
      <MenuItem disabled onClick={() => handleHelpClose('user')}>
        Onboarding Anwender
      </MenuItem>
      {/* <MenuItem onClick={() => handleSwitchThemeMode()}>
        Theme wechseln
      </MenuItem> */}
      <MenuItem onClick={handleLogout}>
        { t('common.button.logout') }
      </MenuItem>
    </Menu>

  );

  return (
    <>
      <AppBar sx={{ backgroundColor: 'background.paper', minHeight: '60px!important' }}>
        <Toolbar sx={{ minHeight: '60px!important' }}>
          <Tooltip title={isOpened ? `${t('navigationDrawer.button.collapse')}` : `${t('navigationDrawer.button.expand')}`}>
            <IconButton
              style={{ marginRight: '14px', marginLeft: '-9px' }}
              onClick={() => toggleIsOpened(!isOpened)}
            >
              {isOpened ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
          </Tooltip>
          <Link
            underline="hover"
            color="primary"
            component={RouterLink}
            to="/app/startscreen"
            sx={{
              mr: {
                xs: 2,
                lg: 7
              }
            }}
          >
            <img style={{ height: '36px' }} src={logo} alt="Logo IT2Match" />
          </Link>

          <Box sx={{ alignSelf: 'stretch', position: 'relative', display: { xs: 'none', md: 'flex' } }}>
            <Button
              sx={quickactionButtonSx}
              onClick={handleClick}
              startIcon={<FlashIcon color="primary" />}
            >
              Schnellaktion
            </Button>
          </Box>
          <Menu
            id="action-menu"
            anchorEl={anchorActionEl}
            open={openAction}
            onClose={handleActionClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => handleActionClose('solution')}>
              <AddIcon />
              {t('common.button.addSingleProduct')}
            </MenuItem>
            <MenuItem onClick={() => handleActionClose('combiProduct')}>
              <AddIcon />
              {t('common.button.addCombiProduct')}
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => handleActionClose('application')}>
              <AddIcon />
              {t('common.button.addApplication')}
            </MenuItem>
          </Menu>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'contents' } }}>
            <Box sx={{ alignSelf: 'stretch', position: 'relative' }}>
              <Button
                sx={eventToggleButtonSx}
                onClick={appToggleEventNotification}
              >
                <CalendarTodayIcon />
              </Button>
              {showEventNotification && <EventNotification />}
            </Box>

            <IconButton
              sx={{ ml: 1 }}
              onClick={handleHelp}
            >
              <HelpOutlineIcon />
            </IconButton>
            <Menu
              sx={{ mt: 1 }}
              id="help-menu"
              anchorEl={anchorHelpEl}
              open={openHelp}
              onClose={handleHelpClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleHelpClose('provider')}>
                <InfoIcon />
                {' '}
                Onboarding Anbieter
              </MenuItem>
              <MenuItem disabled onClick={() => handleHelpClose('user')}>
                <InfoIcon />
                {' '}
                Onboarding Anwender
              </MenuItem>
            </Menu>
            {/* <IconButton onClick={handleSwitchThemeMode}>
              {darkMode ? <LightIcon sx={{ color: 'text.primary' }} />
                : <DarkIcon sx={{ color: 'text.primary' }} /> }
            </IconButton> */}
            <Button
              size="large"
              color="primary"
              sx={{
                fontWeight: 'bold',
                mt: 0,
                ml: 1,
                display: {
                  xs: 'none',
                  md: 'block'
                }
              }}
              onClick={handleLogout}
            >
              { t('common.button.logout') }
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Box sx={{ alignSelf: 'stretch', position: 'relative' }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon color="primary" />
              </IconButton>
            </Box>
          </Box>

        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
};

export default Header;
