import {
  FC, ReactElement,
} from 'react';
import { useRoutes } from 'react-router-dom';

import { ThemeProvider, CssBaseline } from '@mui/material';
// import './assets/css/style.css';

import OnboardingDialog from './components/app/OnboardingDialog';
import CompareDialog from './components/app/CompareDialog';
import ResetPasswordDialog from './components/app/ResetPasswordDialog';
import ResendOptInDialog from './components/app/ResendOptInDialog';
import SystemMessage from './components/ui/SystemMessage';
import ReLogin from './components/app/ReLogin';
import LoadingSpinner from './components/app/LoadingSpinner';
import FlexibleDialog from './components/app/FlexibleDialog';

import './utils/configI18n';
import themes from './themes/it2match';
import routes from './routes';
import { useAppStore, useUserStore } from './state';

const App: FC = (): ReactElement => {
  // check token. when missing then drop the first element in routes array to destroy app routes.
  // first copy original routes to make a working copy
  const allowedRoutes = [...routes];
  if (!localStorage.getItem('token')) {
    allowedRoutes.shift();
  } else if (!useUserStore.getState().userReLogin()) {
    localStorage.removeItem('token');
    allowedRoutes.shift();
  }

  const content = useRoutes(allowedRoutes);

  const app = useAppStore((state:GenericObject) => state);
  const { darkMode } = app;

  const theme = darkMode ? themes.dark : themes.light;

  return (
    <>
      <ReLogin />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <FlexibleDialog />
        <OnboardingDialog />
        <CompareDialog />
        <ResetPasswordDialog />
        <ResendOptInDialog />
        <LoadingSpinner />
        <SystemMessage />
        {content}
      </ThemeProvider>
    </>
  );
};

export default App;
