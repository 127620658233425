/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  CardActions,
  Card as MuiCard,
  Button,
  Dialog,
  IconButton,
  useMediaQuery,
  useTheme,
  DialogContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import {
  AddOutlined as AddIcon,
  EditOutlined as EditIcon,
  DeleteOutlineOutlined as DeleteIcon,
  RemoveRedEyeOutlined as ActivateIcon,
  VisibilityOffOutlined as DeactivateIcon,
  Bookmark as BookmarkMarkedIcon,
  BookmarkBorder as BookmarkIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  InfoOutlined as InfoIcon,
  Extension as MatchingIcon,
  CloudDone,
} from '@mui/icons-material';
import PlaceholderImage from '../../assets/images/placeholder_company.png';
import {
  colorType
} from '../../utils';
import { useCategories } from '../../hooks';
import HoverMenu from './HoverMenu';
import { CardHeader } from './CardHeader';
import { CardActionArea } from './CardActionArea';
import CardMessageArea from './CardMessageArea';
import DetailCard from './DetailCard';

const Card: React.FC<CardProps> = (props: CardProps) => {
  const {
    id,
    categories,
    isDetailView,
    name,
    type,
    logo,
    item,
    summary,
    direction,
    combiproductCompanies,
    status,
    owner,
    suggestionsCount,
    company,
    offerMessage,
    offerCreated,
    referenceModels,
    isCombiProduct,
    handleEdit,
    handleRemove,
    handleActivate,
    handleActivateProject,
    handleDeactivateProject,
    handleLinkup,
    handleBookmark,
    handleLinkupBookmark,
    handleRemoveBookmark,
    handleBlacklistSuggestion,
    handleLinkupBlacklisted,
    handleBookmarkBlacklisted,
    handleMatching,
    handleStartCombiProduct,
    handleResultList,
    handleConfirmOffer,
    handleDeclineOffer,
    handleWithdrawOffer,
    handleConfirmCombiProduct,
    handleDeclineCombiProduct,
    handleUnread,
    handleRead,
  } = props;

  const { t } = useTranslation();

  const {
    tree,
  } = useCategories({ currentItem: props });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const children:any = [];

  tree.industries.forEach((elem: GenericObject) => {
    if (elem.id === 120 && elem.checked) {
      children.push({ id: elem.id, name: elem.name });
    }
    if (elem.id !== 120) {
      if (elem.checked) {
        children.push({ id: elem.id, name: elem.name });
      }
    }
  });

  const [actions, setActions] = useState<CardAction[]>([]);
  const [overflowActions, setOverflowActions] = useState<CardAction[]>([]);

  const path = useLocation().pathname;
  const page: PageType = (path.slice(path.lastIndexOf('/') + 1) as PageType);

  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('');
  const handleToggle = () => setOpen(!open);
  const handleOpenDetailCard = (tabValue: string) => {
    setOpen(true);
    setSelectedTab(tabValue);
  };

  const newRefModels = referenceModels || [];

  const generateCardActions = () => {
    const newActions:CardAction[] = [];

    switch (page) {
      case 'portfolio':
        if (type === 'solution') {
          newActions.push({ label: t('common.button.edit'), startIcon: <EditIcon />, onClick: handleEdit });
          newActions.push({ label: `Matching (${suggestionsCount})`, startIcon: <MatchingIcon />, onClick: handleMatching });
          newActions.push({ label: t('common.button.delete'), startIcon: <DeleteIcon />, onClick: handleRemove });
        }
        if (type === 'combiProduct') {
          newActions.push({
            label: t('common.button.activate'), startIcon: <ActivateIcon />, disabled: status === 50 || status === 240 || status === 10 || !owner, onClick: handleActivate
          });
          if (status === 50 && owner) {
            newActions.push({
              label: `Matching (${suggestionsCount})`, startIcon: <MatchingIcon />, onClick: handleMatching
            });
          }
          newActions.push({
            label: t('common.button.edit'), startIcon: <EditIcon />, disabled: status === 50 || status === 240 || status === 10 || !owner, onClick: handleEdit
          });
          newActions.push({
            label: t('common.button.delete'), startIcon: <DeleteIcon />, disabled: !owner, onClick: handleRemove
          });
        }
        break;
      case 'projects':
        if (status === 40) {
          newActions.push({ label: t('common.button.activate'), startIcon: <ActivateIcon />, onClick: handleActivateProject });
          newActions.push({ label: 'Ergebnisliste', startIcon: <MatchingIcon />, onClick: handleResultList });
          newActions.push({ label: t('common.button.edit'), startIcon: <EditIcon />, onClick: handleEdit });
        }
        if (status === 50) {
          newActions.push({ label: 'Ergebnisliste', startIcon: <MatchingIcon />, onClick: handleResultList });
          newActions.push({ label: t('common.button.edit'), startIcon: <EditIcon />, onClick: handleEdit });
          newActions.push({ label: t('common.button.deactivate'), startIcon: <DeactivateIcon />, onClick: handleDeactivateProject });
        }
        newActions.push({ label: t('common.button.delete'), startIcon: <DeleteIcon />, onClick: handleRemove });
        break;
      case 'incoming':
        if (direction === 'incoming' && type !== 'combiProduct') {
          newActions.push({ label: t('common.button.confirm'), startIcon: <CheckIcon />, onClick: handleConfirmOffer });
          newActions.push({ label: t('common.button.decline'), startIcon: <CloseIcon />, onClick: handleDeclineOffer });
        // newActions.push({ label: 'Als ungelesen markieren', startIcon: <CloseIcon />, onClick: handleUnread });
        } else if (direction === 'incoming' && type === 'combiProduct') {
          newActions.push({ label: t('common.button.confirm'), startIcon: <CheckIcon />, onClick: handleConfirmCombiProduct });
          newActions.push({ label: t('common.button.decline'), startIcon: <CloseIcon />, onClick: handleDeclineCombiProduct });
        }
        break;
      case 'outgoing':
        if (direction === 'outgoing' && type !== 'combiProduct') {
          newActions.push({ label: t('common.button.withdraw'), startIcon: <DeleteIcon />, onClick: handleWithdrawOffer });
        }
        break;
      case 'bookmarks':
        newActions.push({ label: t('common.button.linkup'), startIcon: <CheckIcon />, onClick: handleLinkupBookmark });
        newActions.push({ label: t('common.button.remove'), startIcon: <BookmarkMarkedIcon />, onClick: handleRemoveBookmark });
        break;
      case 'matches':
        if (item?.type === 'solution') {
          newActions.push({ label: t('common.button.cooperate'), startIcon: <AddIcon />, onClick: handleStartCombiProduct });
        }
        break;
      case 'search':
        newActions.push({
          label: t('common.button.linkup'), startIcon: <CheckIcon />, onClick: handleLinkup
        });
        newActions.push({ label: t('common.button.bookmark'), startIcon: <BookmarkIcon />, onClick: handleBookmark });
        break;
      case 'preview':
        newActions.push({ label: t('common.button.bookmark'), startIcon: <BookmarkIcon />, onClick: handleLinkupBookmark });
        break;
      case 'externalsearch':
        newActions.push({ label: t('common.button.bookmark'), startIcon: <BookmarkIcon />, onClick: handleLinkupBookmark });
        break;
      case 'searchforproject':
        newActions.push({ label: t('common.button.linkup'), startIcon: <CheckIcon />, onClick: handleLinkup });
        newActions.push({ label: t('common.button.bookmark'), startIcon: <BookmarkIcon />, onClick: handleBookmark });
        break;
      case 'matching':
        newActions.push({ label: t('common.button.linkup'), startIcon: <CheckIcon />, onClick: handleLinkup });
        newActions.push({ label: t('common.button.bookmark'), startIcon: <BookmarkIcon />, onClick: handleBookmark });
        newActions.push({ label: t('common.button.decline'), startIcon: <CloseIcon />, onClick: handleBlacklistSuggestion });
        break;
      case 'request':
        if (type === 'combiProduct') {
          newActions.push({ label: t('common.button.confirm'), startIcon: <CheckIcon />, onClick: handleConfirmCombiProduct });
          newActions.push({ label: t('common.button.decline'), startIcon: <CloseIcon />, onClick: handleDeclineCombiProduct });
        } else {
          newActions.push({ label: t('common.button.confirm'), startIcon: <CheckIcon />, onClick: handleConfirmOffer });
          newActions.push({ label: t('common.button.decline'), startIcon: <CloseIcon />, onClick: handleDeclineOffer });
        }
        break;
      case 'blacklist':
        newActions.push({ label: t('common.button.linkup'), startIcon: <CheckIcon />, onClick: handleLinkupBlacklisted });
        newActions.push({ label: t('common.button.bookmark'), startIcon: <BookmarkIcon />, onClick: handleBookmarkBlacklisted });
        break;
      default:
        break;
    }

    if (!isDetailView) {
      newActions.push({ label: 'Details', startIcon: <InfoIcon />, onClick: () => handleOpenDetailCard('Allgemein') });
    }

    return newActions;
  };

  useEffect(() => {
    setActions(generateCardActions());
  }, []);

  useEffect(() => {
    if (actions.length > 2) {
      setOverflowActions(actions.slice(2));
    }
  }, [actions]);

  let logoSrc;

  if (logo) {
    logoSrc = logo;
  } else if (company && company.logo) {
    logoSrc = company.logo;
  } else {
    logoSrc = PlaceholderImage;
  }

  const score = Math.floor(item?.featureScore);

  return (
    <>
      {/* begin of card */}
      <MuiCard sx={{
        height: '100%', display: 'flex', flexDirection: 'column', borderLeft: 'solid', borderColor: colorType(type, isCombiProduct), borderWidth: 4
      }}
      >
        {/* Card Heading */}
        {!isDetailView && page !== 'search' && page !== 'edit-singleproduct' && page !== 'companydetails' && page !== 'preview' && (
        <CardHeader
          page={page}
          itemName={item?.name}
          score={score}
          newRefModels={newRefModels}
          isCombiProduct={isCombiProduct}
          handleOpenDetailCard={handleOpenDetailCard}
          type={item?.type}
          owner={owner}
          status={status}
        />
        )}
        {/* Card Action Area */}
        <CardActionArea
          {...props}
          isDetailView={isDetailView}
          handleOpenDetailCard={handleOpenDetailCard}
          open={open}
          type={type}
          logo={item?.company?.logo || logoSrc}
          company={company}
          combiproductCompanies={combiproductCompanies}
          isCombiProduct={isCombiProduct}
          owner={owner}
          name={name}
          summary={summary}
          status={status}
          page={page}
          direction={direction}
          mainIndustries={children}
        />
        {!isDetailView && offerMessage && (
          <>
            <Divider />
            <CardMessageArea
              status={status}
              offerCreated={offerCreated}
              offerMessage={offerMessage}
              direction={direction}
              handleRead={handleRead}
              handleOpenDetailCard={handleOpenDetailCard}
            />
          </>

        )}
        { actions.length > 0 && (
          <Box sx={{ mt: 'auto' }}>
            <Divider sx={{ height: '1px' }} />
            <CardActions sx={{
              py: { xs: 2, sm: 2 }, px: { xs: 2, sm: 2 }, alignContent: 'center'
            }}
            >
              {actions.slice(0, 2).map((action, index) => (
                <Button
                  key={`${action.label}_${index + 1}`}
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{
                    mr: 2, px: 2.5, py: 0.5, flexWrap: 'wrap'
                  }}
                  {...action}
                >
                  {action.label}
                </Button>
              ))}
              {actions.length > 2 && (
              <HoverMenu
                overflowActions={overflowActions}
              />
              )}
            </CardActions>
          </Box>
        )}
      </MuiCard>
      {!isDetailView && (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleToggle}
        maxWidth="md"
        fullWidth
        scroll="body"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {' '}
        <IconButton
          aria-label="close"
          onClick={handleToggle}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 999,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ p: 0, overflow: 'visible' }}>
          <DetailCard
            {...props}
            detailTabValue={selectedTab}
          />
        </DialogContent>
      </Dialog>
      )}
    </>
  );
};

export default Card;
