import { ReactElement } from 'react';

import { Button } from '@mui/material';

const Logos = (props:GenericObject):ReactElement => {
  const { item, handleToggleElement } = props;
  const { id, icon, checked } = item;

  const onClick = () => handleToggleElement(id);

  return (
    <Button
      color="secondary"
      disableRipple
      sx={{
        background: checked ? 'white' : 'white',
        color: checked ? '#B50900' : 'black',
        border: checked ? '2px solid #B50900' : '2px solid transparent',
        boxShadow: '0 2px 5px #00000029',
        px: 1.5,
        py: 1,
        mb: 2,
        mr: 3,
        fontWeight: 'bold',
        fontSize: '12px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '10px',
        width: '195px'
      }}
      startIcon={<img width={40} height={40} src={icon} alt={item.name} />}
      onClick={onClick}
    >
      {item.name}
    </Button>

  );
};

export default Logos;
