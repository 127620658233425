import { FC, ReactElement, useEffect } from 'react';

import { useProductStore, useUserStore } from '../state';

import PortfolioMain from '../components/portfolio/PortfolioMain';

const Portfolio: FC = (): ReactElement => {
  // load all important data from api to display it on the startpage
  useEffect(() => {
    useProductStore.getState().prodGetCategories();
    useUserStore.getState().userGetAccountData()
      .then(() => useProductStore.getState().prodGetNetworks());
  }, []);

  return (
    <PortfolioMain />
  );
};

export default Portfolio;
