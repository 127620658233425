/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import {
  FC, ReactElement, useEffect, useState
} from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';

import {
  Box, Typography, Button, Pagination, Grid, IconButton, ButtonGroup, Paper
} from '@mui/material';

import {
  InfoOutlined,
  Add as AddIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';

import PortfoliolistCard from '../ui/Card';

import {
  useProductStore, usePaginationStore, useUserStore, useAppStore
} from '../../state';
import AddNewCard from './AddNewCard';
import SkeletonCard from '../ui/SkeletonCard';

const PortfolioMain:FC = ():ReactElement => {
  const { company } = useUserStore((state:GenericObject) => state);

  const { t } = useTranslation();

  const { showSkeleton } = useAppStore((state:GenericObject) => state);
  const { prodRemoveProduct, prodActivateCombiProduct } = useProductStore((state:GenericObject) => state);

  const [filter, setFilter] = useState('all');
  const [countPortfolio, setCountPortfolio] = useState(0);
  const [countSingle, setCounSingleProduct] = useState(0);
  const [countCombi, setCountCombiProduct] = useState(0);

  // Portfolio is the aggreagation of singleProducts AND combiProducts!
  const {
    portfolio, singleProducts, combiProducts
  } = useProductStore((state:GenericObject) => state);

  const navigate = useNavigate();

  // Hook for Pagination, start with init()
  const {
    init,
    jump,
    countItems,
    currentPage,
    maxPage,
    paginatedData,
  } = usePaginationStore((state:GenericObject) => state);

  useEffect(() => {
    setCountPortfolio(portfolio.length);
    setCounSingleProduct(singleProducts.length);
    setCountCombiProduct(combiProducts.length);
  }, [portfolio.length, singleProducts.length, combiProducts.length]);

  useEffect(() => {
    // Init Pagination
    const sortedArray = portfolio.sort((a:GenericObject, b:GenericObject) => ((b.id > a.id) ? 1 : ((a.id > b.id) ? -1 : 0)));
    init(sortedArray, 6, 1);
  }, [portfolio.length]);

  const handleFilter = (type:string) => {
    setFilter(type);

    let currentArray:any = [];
    if (type === 'all') currentArray = portfolio;
    if (type === 'single') currentArray = singleProducts;
    if (type === 'combi') currentArray = combiProducts;

    currentArray = currentArray.filter((elem:GenericObject) => elem.status !== 250);

    const sortedArray = currentArray.sort((a:GenericObject, b:GenericObject) => ((b.id > a.id) ? 1 : ((a.id > b.id) ? -1 : 0)));

    init(sortedArray, 6, 1);
  };

  const handleNew = (type:string) => {
    useProductStore.setState({ currentProductProject: null, searchCategories: null });

    if (type === 'single') {
      navigate('/app/edit-singleproduct');
    } else if (type === 'combi') {
      navigate('/app/edit-combiproduct');
    }
  };

  function handleMatching(id: number) {
    navigate('/app/matching', { state: { selectionId: id } });
  }

  function handleActivate(id: number) {
    prodActivateCombiProduct(id);
  }

  function handleEdit(data: GenericObject) {
    useProductStore.setState({ currentProductProject: data });

    if (data.type === 'solution') {
      navigate('/app/edit-singleproduct');
    } else if (data.type === 'combiProduct') {
      navigate('/app/edit-combiproduct');
    }
  }

  function handleRemove(data: GenericObject) {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('dialog.pleaseConfirm') },
      contentItems: [
        {
          text: t('dialog.deleteProduct'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.cancel'),
          color: 'secondary',
        },
        {
          text: t('common.button.delete'),
          color: 'error',
          onClick: () => prodRemoveProduct(data.id, data.type)
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  }

  const handleInfo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('portfolio.info.header') },
      contentItems: [
        {
          text: t('portfolio.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleCombiProductInfo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('common.button.addCombiProduct') },
      contentItems: [
        {
          text: t('portfolio.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  return (
    <Box>
      <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2
      }}
      >
        <Typography variant="h1" fontWeight="bold">{t('portfolio.header')}</Typography>
        <IconButton size="large" color="default" onClick={handleInfo}><InfoOutlined /></IconButton>
      </div>
      <Typography variant="h6" marginBottom={{ xs: 2, lg: 3 }}>{t('portfolio.subheader')}</Typography>
      <Box>
        {portfolio.length > 0 && (
          <>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                size="small"
                color="accentOne"
                onClick={() => handleNew('single')}
                startIcon={<AddIcon />}
              >
                {t('common.button.addSingleProduct')}
              </Button>
              <ButtonGroup>
                <Button
                  variant="contained"
                  color="accentTwo"
                  size="small"
                  onClick={() => handleNew('combi')}
                  startIcon={<AddIcon />}
                >
                  {t('common.button.addCombiProduct')}
                </Button>
                <Button
                  variant="contained"
                  color="accentTwo"
                  size="small"
                  onClick={() => handleCombiProductInfo()}
                >
                  <HelpOutlineIcon />
                </Button>
              </ButtonGroup>
            </Stack>
          </>
        )}
      </Box>
      <Paper sx={{
        marginTop: '20px', display: 'inline-flex', flexWrap: 'wrap', justifyContent: 'start', py: 1, pl: 1, alignContent: 'center', flexDirection: 'row'
      }}
      >
        <Box>
          <Typography>Kategorie</Typography>
          <ButtonGroup>
            <Button
              sx={{
                mb: 1, px: 2, py: 0.7
              }}
              variant="contained"
              color="secondary"
              size="small"
              style={{ opacity: filter === 'all' ? '1' : '0.6' }}
              onClick={() => handleFilter('all')}
            >
              {`${t('matching.filter.all')} (${countPortfolio})`}
            </Button>
            <Button
              sx={{
                mb: 1, px: 2, py: 0.7
              }}
              variant="contained"
              color="secondary"
              size="small"
              style={{ opacity: filter === 'single' ? '1' : '0.6' }}
              onClick={() => handleFilter('single')}
            >
              {`${t('matching.filter.singleProducts')} (${countSingle})`}
            </Button>
            <Button
              sx={{
                mb: 1, mr: 5, px: 2, py: 0.7
              }}
              variant="contained"
              color="secondary"
              size="small"
              style={{ opacity: filter === 'combi' ? '1' : '0.6' }}
              onClick={() => handleFilter('combi')}
            >
              {`${t('matching.filter.combiProducts')} (${countCombi})`}
            </Button>
          </ButtonGroup>
        </Box>
      </Paper>
      <Box>
        {showSkeleton ? (
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }} paddingTop={{ sm: 3 }}>
            { [...Array(Math.min(6, portfolio.length))].map((e, i) => (
              <Grid item xs={12} lg={6} xl={4}>
                <SkeletonCard key={`skel_${i * 2}`} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid
            container
            spacing={{ xs: 3, sm: 4, md: 5 }}
            paddingTop={{ sm: 3, xs: 1 }}
          >
            {paginatedData.map((data:any) => (
              <Grid item xs={12} lg={6} xl={4}>
                <PortfoliolistCard
                  key={`card_${data.type}_${data.id}`}
                  handleEdit={() => handleEdit(data)}
                  handleRemove={() => handleRemove(data)}
                  handleMatching={() => handleMatching(data.id)}
                  handleActivate={() => handleActivate(data.id)}
                  company={company}
                  {...data}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <Box sx={{ pt: 3, clear: 'both' }}>
        {portfolio.length === 0 && (
        <Grid container spacing={{ xs: 3, lg: 4 }}>
          <AddNewCard handleNew={() => handleNew('single')} />
        </Grid>
        )}
        {countItems > 0 && <Pagination count={maxPage} page={currentPage} onChange={(event, value1) => jump(value1)} />}
      </Box>
    </Box>
  );
};
export default PortfolioMain;
