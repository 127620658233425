/* eslint-disable max-len */
import { FC, ReactElement } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Avatar, Box, Typography, List, Link, ListItem, ListItemText, ListSubheader, Tooltip, Grid, IconButton, Divider, Badge
} from '@mui/material';

import {
  Home as HomeIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
  YouTube as YouTubeIcon,
  ChevronRight as ChevronRightIcon,
  BusinessCenter as BusinessCenterIcon,
  Inbox as InboxIcon,
  Bookmark as BookmarkIcon,
  Search as SearchIcon,
  CheckCircle as CheckCircleIcon,
  Inventory as InventoryIcon,
  ManageSearch as ManageSearchIcon,
  Extension as MatchingIcon
} from '@mui/icons-material';

import { useAppStore, useProductStore, useUserStore } from '../../../state';
import PlaceholderLogoCompany from '../../../assets/images/placeholder_company.png';

const footerColumnTitle: GenericObject = {
  fontSize: '10px',
  mb: 1.5,
  fontWeight: '600',
  textTransform: 'uppercase'
};

const Navigation: FC = (): ReactElement => {
  const { t } = useTranslation();
  const user = useUserStore((state:GenericObject) => state);
  const drawerIsOpened = useAppStore((state:GenericObject) => state.drawerIsOpened);
  const { offersIncoming } = useProductStore((state:GenericObject) => state);

  let nameCompany = '...loading';

  // get the name of the page to highlight menu items and icons
  const page = useLocation().pathname.split('/')[2];

  const name = user?.company?.name;
  const logoCompany = user?.company?.logo;

  if (name) {
    nameCompany = `${name}`;
  }

  const footerSocialIconSx: GenericObject = {
    borderWidth: '1px',
    borderStyle: 'solid ',
    borderColor: 'secondary.dark',
    borderRadius: '50%',
    mx: 0.2,
    width: '40px',
    height: '40px'
  };

  const footerSocialIconSvgSx: GenericObject = {
    width: '20px',
    height: '20px'
  };

  const sxButtom: GenericObject = {
    display: 'flex',
    mt: 15,
    textAlign: 'center',
    paddingBottom: 10,
  };

  let sxProfile: GenericObject = {
    ml: 1, opacity: 1, height: '50px', whiteSpace: 'nowrap'
  };

  let sxText: GenericObject = {
    ml: 3, fontWeight: '700', fontSize: '14px'
  };

  let sxSubHeader: GenericObject = {
    ...sxProfile, mt: 1, ml: 0, height: 'auto', whiteSpace: 'nowrap', mb: '4px', px: 2
  };

  let sxProfileLink: GenericObject = {
    display: 'block',
    px: 2,
    py: 1,
    '&:hover': {
      backgroundColor: 'secondary.light',
    }
  };
  let sxProfileLinkHighlighted: GenericObject = {
    display: 'block',
    px: 2,
    py: 1,
    backgroundColor: 'secondary.main',
  };

  let sxProfileAvtar: GenericObject = {
    display: 'flex'
  };

  let sxLink: GenericObject = {
    display: 'flex',
    px: 2,
    py: 0.3,
    backgroundColor: 'common.white',
    '&:hover': {
      backgroundColor: 'secondary.light',
    }
  };

  let sxLinkHighlighted: GenericObject = {
    display: 'flex', px: 2, py: 0.3, backgroundColor: 'secondary.main'
  };

  if (!drawerIsOpened) {
    sxProfile = {
      ...sxProfile, opacity: 0, height: 0, overflow: 'hidden'
    };
    sxSubHeader = {
      ...sxSubHeader, opacity: 0, height: 0, overflow: 'hidden', marginBottom: '0'
    };
    sxText = {
      ...sxText, opacity: 0
    };
    sxProfileLink = {
      ...sxProfileLink,
    };
    sxProfileLinkHighlighted = {
      ...sxProfileLinkHighlighted,
    };
    sxLink = {
      ...sxLink, justifyContent: 'center'
    };
    sxLinkHighlighted = {
      ...sxLinkHighlighted, justifyContent: 'center'
    };
    sxProfileAvtar = {
      ...sxProfileAvtar, justifyContent: 'center'
    };
  }

  // const theme = useTheme();

  return (
    <Box sx={{ fontSize: '14px' }}>
      <Box sx={{ mt: 3 }} />
      {/* <Link sx={sxProfileLink} style={page === 'user-profile' ? highlighted : {}} underline="none" component={RouterLink} to="/app/user-profile">
        <Box sx={sxProfileAvtar}>

          <Tooltip title={`${nameUser}`}>
            <Avatar src={logoPerson || PlaceholderLogoPerson} />
          </Tooltip>
          {drawerIsOpened && (
            <Box sx={sxProfile}>
              <Typography sx={{
                fontWeight: 'bold', fontSize: 'inherit', overflow: 'hidden', textOverflow: 'ellipsis', width: '13rem'
              }}
              >
                {nameUser}
              </Typography>
              <Typography sx={{ fontSize: 'inherit' }}>{t('navigationDrawer.button.showProfile')}</Typography>
            </Box>
          )}
        </Box>
      </Link> */}

      <Link sx={page === 'profile' ? sxProfileLinkHighlighted : sxProfileLink} underline="none" component={RouterLink} to="/app/profile">
        <Box sx={sxProfileAvtar}>
          <Tooltip title={`${nameCompany}`}>
            <Avatar sx={{ border: '1px solid #000' }} src={logoCompany || PlaceholderLogoCompany} />
          </Tooltip>
          {drawerIsOpened && (
            <Box sx={sxProfile}>
              <Typography sx={{
                fontWeight: 'bold', fontSize: 'inherit', overflow: 'hidden', textOverflow: 'ellipsis', width: '13rem'
              }}
              >
                {nameCompany}
              </Typography>
              <Typography sx={{ fontSize: 'inherit' }}>{t('navigationDrawer.button.showProfile')}</Typography>
            </Box>
          )}
        </Box>
      </Link>

      <List>
        <ListSubheader sx={sxSubHeader} color="primary" disableSticky>
          IT2match
        </ListSubheader>
        <ListItem
          sx={page === 'startscreen' ? sxLinkHighlighted : sxLink}
          disablePadding
          component={RouterLink}
          to="/app/startscreen"
          key="0820"
        >
          <Tooltip title="Startseite">
            <HomeIcon color={page === 'startscreen' ? 'primary' : 'action'} />
          </Tooltip>
          {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>Start</Typography>
            </ListItemText>
          )}
          {drawerIsOpened && <ChevronRightIcon color="primary" />}
        </ListItem>
        <ListItem
          sx={page === 'matching' ? sxLinkHighlighted : sxLink}
          disablePadding
          component={RouterLink}
          to="/app/matching"
          key="0815"
        >
          <Tooltip title={`${t('bottomNavigation.matching')}`}>
            <MatchingIcon color={page === 'matching' ? 'primary' : 'action'} />
          </Tooltip>
          {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>{t('bottomNavigation.matching')}</Typography>
            </ListItemText>
          )}
          {drawerIsOpened && <ChevronRightIcon color="primary" />}
        </ListItem>
        <ListItem
          sx={page === 'search' ? sxLinkHighlighted : sxLink}
          disablePadding
          button
          component={RouterLink}
          to="/app/search"
          key="0816"
        >
          <Tooltip title={`${t('bottomNavigation.search')}`}>
            <SearchIcon
              color={page === 'search' ? 'primary' : 'action'}
            />
          </Tooltip>
          {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>{t('bottomNavigation.search')}</Typography>
            </ListItemText>
          )}
          {drawerIsOpened && <ChevronRightIcon color="primary" />}
        </ListItem>

      </List>

      <List>
        <ListSubheader sx={sxSubHeader} color="primary" disableSticky>
          {t('navigationDrawer.header.section1')}
        </ListSubheader>

        <ListItem
          sx={page === 'portfolio' || page === 'edit-singleproduct' || page === 'edit-combiproduct' ? sxLinkHighlighted : sxLink}
          disablePadding
          button
          component={RouterLink}
          to="/app/portfolio"
          key="0817"
        >
          <Tooltip title={`${t('navigationDrawer.button.portfolio')}`}>
            <BusinessCenterIcon color={page === 'portfolio' ? 'primary' : 'action'} />
          </Tooltip>
          {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>{t('navigationDrawer.button.portfolio')}</Typography>
            </ListItemText>
          )}
          {drawerIsOpened && <ChevronRightIcon color="primary" />}
        </ListItem>

        <ListItem sx={page === 'projects' ? sxLinkHighlighted : sxLink} disablePadding button component={RouterLink} to="/app/projects" key="0816">
          <Tooltip title={`${t('navigationDrawer.button.request')}`}>
            <ManageSearchIcon color={page === 'projects' ? 'primary' : 'action'} />
          </Tooltip>
          {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>{t('navigationDrawer.button.request')}</Typography>
            </ListItemText>
          )}
          {drawerIsOpened && <ChevronRightIcon color="primary" />}
        </ListItem>

        <ListSubheader sx={sxSubHeader} color="primary" disableSticky>
          {t('navigationDrawer.header.section2')}
        </ListSubheader>

        <ListItem sx={page === 'requests' ? sxLinkHighlighted : sxLink} disablePadding button component={RouterLink} to="/app/requests/incoming" key="07007">
          <Tooltip title={`${t('navigationDrawer.button.offers')}`}>
            <Badge badgeContent={offersIncoming.filter((offer: any) => offer.status === 10).length} color="primary">
              <InboxIcon color={page === 'requests' ? 'primary' : 'action'} />
            </Badge>
          </Tooltip>
          {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>{t('navigationDrawer.button.offers')}</Typography>
            </ListItemText>
          )}
          {drawerIsOpened && (
            <ChevronRightIcon color="primary" />
          )}
        </ListItem>

        <ListItem sx={page === 'bookmarks' ? sxLinkHighlighted : sxLink} disablePadding component={RouterLink} to="/app/bookmarks" key="0811">
          <Tooltip title={`${t('navigationDrawer.button.bookmarks')}`}>
            <BookmarkIcon color={page === 'bookmarks' ? 'primary' : 'action'} />
          </Tooltip>
          {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>{t('navigationDrawer.button.bookmarks')}</Typography>
            </ListItemText>
          )}
          {drawerIsOpened && <ChevronRightIcon color="primary" />}
        </ListItem>

        <ListItem sx={page === 'matches' ? sxLinkHighlighted : sxLink} disablePadding component={RouterLink} to="/app/matches" key="0819">
          <Tooltip title={`${t('navigationDrawer.button.matches')}`}>
            <CheckCircleIcon color={page === 'matches' ? 'primary' : 'action'} />
          </Tooltip>
          {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>{t('navigationDrawer.button.matches')}</Typography>
            </ListItemText>
          )}
          {drawerIsOpened && <ChevronRightIcon color="primary" />}
        </ListItem>

        <ListItem sx={page === 'blacklist' ? sxLinkHighlighted : sxLink} disablePadding component={RouterLink} to="/app/blacklist" key="08007">
          <Tooltip title={`${t('navigationDrawer.button.blacklist')}`}>
            <InventoryIcon color={page === 'blacklist' ? 'primary' : 'action'} />
          </Tooltip>
          {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>{t('navigationDrawer.button.blacklist')}</Typography>
            </ListItemText>
          )}
          {drawerIsOpened && <ChevronRightIcon color="primary" />}
        </ListItem>

        {drawerIsOpened && (
          <>
            <Divider />
            <List>
              <ListItem
                sx={page === 'imprint' ? sxLinkHighlighted : sxLink}
                disablePadding
                component={RouterLink}
                to="/app/imprint"
                key="0890"
              >
                <ListItemText>
                  <Typography sx={sxText}>{t('footer.text.imprint')}</Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                sx={page === 'dataprotection' ? sxLinkHighlighted : sxLink}
                disablePadding
                component={RouterLink}
                to="/app/dataprotection"
                key="0891"
              >
                <ListItemText>
                  <Typography sx={sxText}>{t('footer.text.privacy')}</Typography>
                </ListItemText>
              </ListItem>
            </List>
          </>
        ) }
      </List>
      {drawerIsOpened && (
        <Grid container sx={sxButtom} justifyContent="center">
          <Grid item xs={12} md={12}>
            <Typography sx={footerColumnTitle}>{ t('footer.text.follow') }</Typography>
            <Box>
              <IconButton href="https://twitter.com/itwirtschaft?lang=de" sx={footerSocialIconSx} target="_blank">
                <TwitterIcon sx={footerSocialIconSvgSx} />
              </IconButton>
              <IconButton href="https://www.facebook.com/KIWirtschaft/" sx={footerSocialIconSx} target="_blank">
                <FacebookIcon sx={footerSocialIconSvgSx} />
              </IconButton>
              <IconButton href="https://de.linkedin.com/company/kompetenzzentrum-it-wirtschaft" sx={footerSocialIconSx} target="_blank">
                <LinkedInIcon sx={footerSocialIconSvgSx} />
              </IconButton>
              <IconButton href="https://www.youtube.com/channel/UCLdGM-ICYj_-iiMotyO4tJg" sx={footerSocialIconSx} target="_blank">
                <YouTubeIcon sx={footerSocialIconSvgSx} />
              </IconButton>
              <IconButton href="https://podcasts.apple.com/de/podcast/kooperationen-in-der-it-wirtschaft/id1489448932" sx={footerSocialIconSx} target="_blank">
                <svg width="16" height="16" aria-hidden="true" data-prefix="fas" data-icon="podcast" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="currentColor" d="M267.429 488.563C262.286 507.573 242.858 512 224 512c-18.857 0-38.286-4.427-43.428-23.437C172.927 460.134 160 388.898 160 355.75c0-35.156 31.142-43.75 64-43.75s64 8.594 64 43.75c0 32.949-12.871 104.179-20.571 132.813zM156.867 288.554c-18.693-18.308-29.958-44.173-28.784-72.599 2.054-49.724 42.395-89.956 92.124-91.881C274.862 121.958 320 165.807 320 220c0 26.827-11.064 51.116-28.866 68.552-2.675 2.62-2.401 6.986.628 9.187 9.312 6.765 16.46 15.343 21.234 25.363 1.741 3.654 6.497 4.66 9.449 1.891 28.826-27.043 46.553-65.783 45.511-108.565-1.855-76.206-63.595-138.208-139.793-140.369C146.869 73.753 80 139.215 80 220c0 41.361 17.532 78.7 45.55 104.989 2.953 2.771 7.711 1.77 9.453-1.887 4.774-10.021 11.923-18.598 21.235-25.363 3.029-2.2 3.304-6.566.629-9.185zM224 0C100.204 0 0 100.185 0 224c0 89.992 52.602 165.647 125.739 201.408 4.333 2.118 9.267-1.544 8.535-6.31-2.382-15.512-4.342-30.946-5.406-44.339-.146-1.836-1.149-3.486-2.678-4.512-47.4-31.806-78.564-86.016-78.187-147.347.592-96.237 79.29-174.648 175.529-174.899C320.793 47.747 400 126.797 400 224c0 61.932-32.158 116.49-80.65 147.867-.999 14.037-3.069 30.588-5.624 47.23-.732 4.767 4.203 8.429 8.535 6.31C395.227 389.727 448 314.187 448 224 448 100.205 347.815 0 224 0zm0 160c-35.346 0-64 28.654-64 64s28.654 64 64 64 64-28.654 64-64-28.654-64-64-64z" />
                </svg>
              </IconButton>
              <IconButton href="https://soundcloud.com/itwirtschaft" sx={footerSocialIconSx} target="_blank">
                <svg width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M17 11h1c1.38 0 3 1.274 3 3c0 1.657 -1.5 3 -3 3l-6 0v-10c3 0 4.5 1.5 5 4z" />
                  <line x1="9" y1="8" x2="9" y2="17" />
                  <line x1="6" y1="17" x2="6" y2="10" />
                  <line x1="3" y1="16" x2="3" y2="14" />
                </svg>
              </IconButton>
            </Box>
          </Grid>
          <Typography sx={{
            mt: 2, color: 'text.primary', fontSize: '12px', textAlign: 'center'
          }}
          >
            {`© ${new Date().getFullYear()} ${t('footer.text.companyName')}`}
          </Typography>
        </Grid>
      )}
    </Box>
  );
};

export default Navigation;
