/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import {
  FC, ReactElement, useEffect
} from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Box, Typography, Button, Pagination, IconButton, Grid
} from '@mui/material';

import { InfoOutlined, Add as AddIcon } from '@mui/icons-material';
import ProjectslistCard from '../ui/Card';

import {
  useProductStore, usePaginationStore, useAppStore, useUserStore
} from '../../state';
import SkeletonCard from '../ui/SkeletonCard';
import { getStringOfPositivesFromCategories } from '../../utils';

const ProjectsMain:FC = ():ReactElement => {
  const { company } = useUserStore((state:GenericObject) => state);
  const {
    projects,
  } = useProductStore((state:GenericObject) => state);
  const { t } = useTranslation();
  // Test
  const navigate = useNavigate();
  const { showSkeleton } = useAppStore((state:GenericObject) => state);

  // Hook for Pagination, start with init()
  const {
    init,
    jump,
    countItems,
    currentPage,
    maxPage,
    paginatedData,
  } = usePaginationStore((state:GenericObject) => state);

  const { prodRemoveProject, prodActivateProject, prodDeactivateProject } = useProductStore((state:GenericObject) => state);

  useEffect(() => {
    // Init Pagination
    if (projects) {
      const sortedArray = projects.sort((a:GenericObject, b:GenericObject) => ((b.id > a.id) ? 1 : ((a.id > b.id) ? -1 : 0)));
      init(sortedArray, 6, 1);
    }
  }, [projects]);

  function handleActivateProject(id:number) {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('dialog.pleaseConfirm') },
      contentItems: [
        {
          text: t('dialog.activateProject'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.cancel'),
          color: 'secondary',
        },
        {
          text: t('common.button.activate'),
          color: 'success',
          onClick: () => prodActivateProject(id)
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  }

  function handleDeactivateProject(id:number) {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('dialog.pleaseConfirm') },
      contentItems: [
        {
          text: t('dialog.deactivateProject'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.cancel'),
          color: 'secondary',
        },
        {
          text: t('common.button.deactivate'),
          color: 'error',
          onClick: () => prodDeactivateProject(id)
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  }

  const handleEdit = (data: GenericObject) => {
    useProductStore.setState({ currentProductProject: data });
    navigate('/app/edit-project');
  };

  function handleMatching(id: number) {
    navigate('/app/matching', { state: { selectionId: id } });
  }

  function handleResultList(categories: any) {
    const positives = getStringOfPositivesFromCategories(categories);
    navigate('/app/searchforproject', { state: { positives } });
  }

  const handleRemove = (id: number) => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('dialog.pleaseConfirm') },
      contentItems: [
        {
          text: t('dialog.deleteProject'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.cancel'),
          color: 'secondary',
        },
        {
          text: t('common.button.delete'),
          color: 'error',
          onClick: () => prodRemoveProject(id)
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleInfo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('projects.info.header') },
      contentItems: [
        {
          text: t('projects.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleNew = () => {
    useProductStore.setState({ currentProductProject: null });

    navigate('/app/edit-project');
  };

  return (
    <Box>
      <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2
      }}
      >
        <Typography variant="h1" color="black" fontWeight="bold">{t('projects.header')}</Typography>
        <IconButton size="large" color="default" onClick={handleInfo}><InfoOutlined /></IconButton>
      </div>
      <Typography variant="h6" color="black" marginBottom={{ xs: 2, lg: 3 }}>{t('projects.subheader')}</Typography>
      <div style={{
        display: 'flex', flexWrap: 'wrap', marginBottom: 20, alignContent: 'center'
      }}
      >
        <Button
          sx={{
            mb: 1, mr: 2, px: 2, py: 0.7
          }}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleNew()}
          startIcon={<AddIcon />}
        >
          {t('common.button.addApplication')}
        </Button>
      </div>
      <div>
        {showSkeleton ? (
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }}>
            { [...Array(projects.length)].map((e, i) => (
              <Grid item xs={12} lg={6} xl={4}>
                <SkeletonCard key={`skel_${i * 2}`} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }}>
            {paginatedData.map((data:any) => (
              <Grid item xs={12} lg={6} xl={4}>
                <ProjectslistCard
                  key={`card_${data.type}_${data.id}`}
                  handleActivateProject={() => handleActivateProject(data.id)}
                  handleDeactivateProject={() => handleDeactivateProject(data.id)}
                  handleEdit={() => handleEdit(data)}
                  handleRemove={() => handleRemove(data.id)}
                  handleMatching={() => handleMatching(data.id)}
                  handleResultList={() => handleResultList(data.categories)}
                  company={company}
                  {...data}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
      <Box sx={{ pt: 3, clear: 'both' }}>
        {countItems === 0 && <Typography variant="h6">{t('common.text.noData')}</Typography>}
        {countItems > 0 && <Pagination count={maxPage} page={currentPage} onChange={(event, value1) => jump(value1)} />}
      </Box>
    </Box>
  );
};
export default ProjectsMain;
