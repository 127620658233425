import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import i18n from 'i18next';
import { useAppStore } from '../state';

const API_BASEURL = process.env.REACT_APP_API_BASEURL;
const CTA_EVENT_URL = process.env.REACT_APP_CTA_EVENT_URL;
const processData = async (options: GenericObject = {}): Promise<GenericObject> => {
  const username:string = process.env.REACT_APP_AUTH_USERNAME || 'unknown';
  const password:string = process.env.REACT_APP_AUTH_PASSWORD || 'unknown';

  let requestConfig: AxiosRequestConfig = {
    baseURL: API_BASEURL,
    url: '/login',
    method: 'post',
    timeout: 20000,
    data: {},
    auth: {
      username,
      password
    },
    validateStatus: (status) => status >= 200 && status < 500,
  };

  // read token from local storage
  let token = localStorage.getItem('token');

  // if onboarding token is submitted then take it, delete it from object
  if (options.onboardingToken) {
    token = options.onboardingToken;
    // eslint-disable-next-line no-param-reassign
    delete options.onbonboardingToken;
  }

  // set the X-AUTH-TOKEN if it is not the Wordpress-API to geht the latest event
  // token must be exist in the localStorage!
  if (options.url !== CTA_EVENT_URL) {
    if (token) {
      requestConfig.headers = { 'X-AUTH-TOKEN': token };
    }
  }

  // if preview (a search without login) then use a special token
  if (options.url === '/search' && !token) {
    // eslint-disable-next-line no-param-reassign
    options.url = '/search';
    // MUST BE STORED IN A ENV-FILE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // STAGE
    // requestConfig.headers = { 'X-AUTH-TOKEN': 'dweanFPSQDk6vG-of_eUG-hl7tQxsZq8nJENJDmAtuU' };
    // DEV
    // requestConfig.headers = { 'X-AUTH-TOKEN': 'TsKakkIdjccaElIQIRkMw7qOi_WrNcZBWN-OCZN6qUM' };
    // LIVE
    // requestConfig.headers = { 'X-AUTH-TOKEN': 'uVuNqshfdNitmxKMFBxsGOoNOd5qZzYdFf8nZLupf0w' };
  }

  // mix default config with parameters
  requestConfig = {
    ...requestConfig,
    ...options,
  };
  let response: AxiosResponse;

  try {
    response = await axios(requestConfig);
  } catch (err) {
    const payload = {
      error: true
    };
    useAppStore.setState({
      alert: {
        error: true, duration: 10000, severity: 'error', text: i18n.t('common.error.backend'),
      }
    });

    return payload;
  }

  const { status, statusText, data } = response;

  // return data immediately if it is the Wordpress-API to get the latest event
  if (response.request.responseURL === CTA_EVENT_URL) {
    if (response.status === 200) {
      return { data };
    }
  }

  // check error in data
  if (!data.success) {
    const payload = {
      error: true
    };

    useAppStore.setState({
      alert: {
        error: true, severity: 'error', text: `${status} ${statusText}`
      }
    });

    return payload;
  }
  return { data };
};

export default processData;
